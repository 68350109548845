
































import Vue from "vue";
import scroll from "vue-seamless-scroll";
import wxShare from "../../tool/wechat";
import { Toast, Popup } from "vant";
Vue.use(Toast).use(Popup);
export default Vue.extend({
  created() {
    const _this = this;
    /**接受客户端数据 */
    (window as any).sendWebMessage = function(val) {
      let obj = JSON.parse(val);
      if (obj.event == "click" && obj.name == "back") {
        _this.back();
      }
    };
  },
  methods: {
    /**
     * 返回页面
     */
    back() {
      if (this.$route.query.source == "web") {
        window.history.back();
      } else {
        this.$bridge.NativeBridge("blackAction", {});
      }
    }
  }
});
