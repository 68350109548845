






























































import Vue from "vue";
import { slider, slideritem } from "vue-concise-slider";
import wxShare from "../../tool/wechat";
import { Toast, Lazyload, Popup, Dialog } from "vant";
Vue.use(Toast)
  .use(Lazyload)
  .use(Dialog)
  .use(Popup);
export default Vue.extend({
  mixins: [wxShare],
  data() {
    return {
      lazyImg: [
        "https://cdn.fitapp.cn/vue/subject/2021-1-5/P2.png",
        "https://cdn.fitapp.cn/vue/subject/2021-1-5/P3.png",
        "https://cdn.fitapp.cn/vue/subject/2021-1-5/P4.png",
        "https://cdn.fitapp.cn/vue/subject/2021-1-5/P5.png",
        "https://cdn.fitapp.cn/vue/subject/2021-1-5/P7.png",
        "https://cdn.fitapp.cn/vue/subject/2021-1-5/P8.png",
        "https://cdn.fitapp.cn/vue/subject/2021-1-5/P9.png"
      ],
      info: {
        andComId: "",
        comdDesc: "",
        userBuyStatus: "1",
        actFlg: "1",
        lstAct0GrpComData: [
          {
            grpTitle: "",
            activityPrice: ""
          }
        ]
      },
      someList: [
        "https://cdn.fitapp.cn/vue/subject/2021-1-5/c1.png",
        "https://cdn.fitapp.cn/vue/subject/2021-1-5/c2.png",
        "https://cdn.fitapp.cn/vue/subject/2021-1-5/c3.png",
        "https://cdn.fitapp.cn/vue/subject/2021-1-5/c4.png"
      ],
      options: {
        itemAnimation: true,
        centeredSlides: true,
        loopedSlides: 2,
        slidesToScroll: 1,
        loop: true,
        autoplay: "3000"
      }
    };
  },
  created() {
    this.getPageInfo();
    /**
     * 右上角分享初始化
     */
    this.$bridge.setShareInfo({
      title: "🎁点一点｜领显瘦又有型的福利",
      link: this.$F.getOrigin() + "/subject/2021-1-5",
      imgUrl: "https://cdn.fitapp.cn/vue/subject/2021-1-5/wxshare.jpg",
      desc: "暖冬派对进行中，领买一赠一，快快戳我",
      ctitle: "🎁点一点｜领显瘦又有型的福利",
      olapflg: "25",
      display: "1",
      shareList: "1,2"
    });
    /**
     * 设置微信分享
     */
    if (this.$B.browser() == "wechat") {
      this.setShare();
    }
  },
  methods: {
    /**
     * 获取基础数据
     */
    getPageInfo() {
      const _this = this;
      _this.$axios
        .post(
          "/wuAPI/fitDcApi/fitH5Act/getGrpActComResource",
          _this.$qs.stringify({
            "form.reqUid": "64789-vue",
            "form.userId": _this.$F.userid() == 1 ? "10092" : _this.$F.userid(),
            "form.mbType": _this.$B.system() == "ios" ? "1" : "0",
            "form.channel": "dc2",
            "form.grpType": "14"
          })
        )
        .then(res => {
          if (res.data.result == "Y") {
            let entRet = res.data.entRet;
            _this.info = entRet;
          } else {
            Toast("页面加载失败");
          }
        })
        .catch(res => {
          Toast("页面加载失败");
        });
    },
    /**
     * 微信分享
     */
    setShare() {
      const shareInfo = {
        title: `🎁点一点｜领显瘦又有型的福利`,
        desc: `暖冬派对进行中，领买一赠一，快快戳我`,
        link: this.$F.getOrigin() + "/subject/2021-1-5",
        img: "https://cdn.fitapp.cn/vue/subject/2021-1-5/wxshare.jpg"
      };
      (this as any).wechatShare(shareInfo);
    },
    /**
     * 支付
     */
    payFn() {
      window.console.log(3123);
      const _this = this;
      if (_this.$B.browser() != "fit") {
        _this.goFit();
        return false;
      }
      if (_this.info.actFlg == "-1") {
        Toast("活动未开始");
        return false;
      }
      if (_this.info.actFlg == "-2") {
        Toast("活动已结束");
        return false;
      }
      if (_this.$F.dev() >= 620) {
        const popGoodsInfo = {
          commodityId: _this.info.andComId, //商品id
          callType: "5",
          commodityTitle: _this.info.lstAct0GrpComData[0].grpTitle, //商品标题
          commodityComment: _this.info.comdDesc, //商品描述
          comment: _this.info.comdDesc, //商品描述
          price: _this.info.lstAct0GrpComData[0].activityPrice, //价格
          commodityType: "3",
          buyComment: _this.info.comdDesc,
          buyTitle: _this.info.lstAct0GrpComData[0].grpTitle //商品标题
        };
        let payObj = {
          showApplePay: "0",
          goodsInfo: JSON.stringify(popGoodsInfo),
          cusColor: "#DBB76A",
          androidStyleType: "0",
          callback: "successFn",
          paySort: "0,1",
          showPaySheet: "0"
        };

        if (_this.$F.dev() >= 635 && _this.$B.system() == "ios") {
          payObj.showPaySheet = "0";
          payObj.showApplePay = "1";
          payObj.paySort = "";
        }
        //支付
        _this.$bridge.NativeBridge("payPop", payObj);

        (window as any).successFn = function(msg) {
          msg = JSON.parse(msg);
          if (msg.type == "success") {
            _this.info.userBuyStatus = "2";
            if (_this.$B.system() != "ios") {
              Toast("支付成功");
            }
            (window as any).gio("track", "web_bnt_click", {
              target_module: "VIP年会员新年福利购支付成功",
              target_title: "VIP年会员新年福利购",
              target_id: _this.$F.userid()
            });
          }
        };
      } else {
        Toast("请下载最新版app！");
      }
      (window as any).gio("track", "web_bnt_click", {
        target_module: "点击领取VIP年会员福利",
        target_title: "VIP年会员新年福利购",
        target_id: _this.$F.userid()
      });
    },
    /**
     * 微信打开活动页面openFit
     */
    goFit() {
      this.$openPage.openFit("1", {
        url: this.$F.getOrigin() + "/subject/2021-1-5"
      });
    }
  },
  components: {
    slider,
    slideritem
  }
});
