

































































































































import Vue from "vue";
import { Toast, Lazyload, Popup } from "vant";
import wxShare from "../../tool/wechat";
Vue.use(Toast)
  .use(Lazyload)
  .use(Popup);
const fitUid = window.localStorage.getItem("fitUid");
let isClick = true;
export default Vue.extend({
  mixins: [wxShare],
  data() {
    return {
      browser: this.$B.browser(),
      show: false,
      helpFlg: false,
      loadFlg: false,
      isInviteOver: -1,
      tipsTitle: "谢谢你的鼓励！",
      info: {
        helpFlg: "0", //是否助力
        authHeadImg: "",
        lstInvUser: [{ headImg: "" }, { headImg: "" }, { headImg: "" }]
      }
    };
  },
  beforeCreate() {
    const code = this.$route.query.code;
    if (!fitUid) {
      if (!code) {
        var URL = window.location.href; // window.location.href;
        window.location.href =
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxf77f60f84eaa3fc2&redirect_uri=" +
          URL +
          "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
      }
    }
  },
  created() {
    this.getPageInfo();
    /**
     * 设置微信分享
     */
    if (this.browser == "wechat") {
      this.setShare();
    }
  },
  methods: {
    /**
     * 获取基础数据
     */
    getPageInfo() {
      const _this = this;
      (_this as any).renewFlg = _this.$route.query.renew ? true : false; // 续费状态
      let trainid = _this.$route.query.trainid; //活动id
      _this.$axios
        .post(
          "/wuAPI/fitDcApi/fitH5Act2020/get20Double11wxUDataRes",
          _this.$qs.stringify({
            "form.reqUid": "64789-vue",
            "form.mbType": _this.$B.system() == "ios" ? "1" : "0",
            "form.channel": "wx",
            "form.authUid": fitUid,
            "form.authorCode": this.$route.query.code,
            "form.inviteUid": this.$route.query.inviteid
          })
        )
        .then(res => {
          if (res.data.result == "Y") {
            const entRet = res.data.entRet;
            _this.info = entRet;
            window.localStorage.setItem("fitUid", entRet.authUid);
            for (let i = 0; i < entRet.lstInvUser.length; i++) {
              if (entRet.lstInvUser[i].headImg) {
                _this.isInviteOver = i;
              }
            }
          }
        })
        .catch(res => {
          Toast("页面加载失败");
        });
    },
    /**
     * 微信分享
     */
    setShare() {
      const shareInfo = {
        title: `【Fit App】好友助力，免费送VIP`,
        desc: `11.11重燃开练！会员买1送1年，还有7重健康权益相送`,
        link: window.location.href,
        img: "https://cdn.fitapp.cn/vue/subject/11-11/share2.png"
      };
      (this as any).wechatShare(shareInfo);
    },
    /**
     * 微信打开活动页面openFit
     */
    goFit() {
      const _this = this;
      this.$openPage.openFit("1", {
        url: this.$F.getOrigin() + "/subject/2020-11-11"
      });
      (window as any).gio("track", "web_bnt_click", {
        target_module: "我也要领",
        target_title: "被邀请人页面",
        target_id: _this.$F.userid()
      });
    },
    add() {
      const _this = this;
      if (isClick) {
        isClick = false;
        if (!window.localStorage.getItem("fitUid")) {
          Toast("未授权，无法助力");
          isClick = true;
          return;
        }
        if (_this.info.helpFlg == "1") {
          isClick = true;
          Toast("每个账号仅可助力1次");
          return;
        }
        if (
          window.localStorage.getItem("fitUid") == this.$route.query.inviteid
        ) {
          isClick = true;
          Toast("仅限好友助力");
          return;
        }
        this.loadFlg = true;
        _this.$axios
          .post(
            "/wuAPI/fitDcApi/fitH5Act2020/get20Double11ActHelpURes",
            _this.$qs.stringify({
              "form.reqUid": "64789-vue",
              "form.mbType": _this.$B.system() == "ios" ? "1" : "0",
              "form.channel": "wx",
              "form.authUid": window.localStorage.getItem("fitUid"),
              "form.inviteUid": this.$route.query.inviteid
            })
          )
          .then(res => {
            if (res.data.result == "Y") {
              if (res.data.entRet.helpFlg == "1") {
                Toast("每个账号仅可助力1次");
                this.loadFlg = false;
                isClick = true;
                return;
              } else if (res.data.entRet.helpFlg == "2") {
                this.tipsTitle = "谢谢你的鼓励！";
              } else if (res.data.entRet.helpFlg == "3") {
                this.tipsTitle = "好友已完成助力谢谢你的鼓励！";
              }
              _this.info.helpFlg = "1";
              _this.info.lstInvUser[_this.isInviteOver + 1].headImg =
                _this.info.authHeadImg;
              _this.isInviteOver = _this.isInviteOver + 1;
              _this.helpFlg = true;
              isClick = true;
            }
            this.loadFlg = false;
          })
          .catch(res => {
            isClick = true;
            this.loadFlg = false;
            Toast("页面加载失败");
          });
      }

      (window as any).gio("track", "web_bnt_click", {
        target_module: "为TA助力",
        target_title: "被邀请人页面",
        target_id: _this.$F.userid()
      });
    }
  }
});
