




























import Vue from "vue";
import { Picker, Toast, Icon, Popup } from "vant";
Vue.use(Picker)
  .use(Toast)
  .use(Icon)
  .use(Popup);
export default Vue.extend({
  data() {
    return {
      list: []
    };
  },
  created() {
    this.getPageInfo();
  },
  methods: {
    getPageInfo() {
      let _this = this;
      _this.$axios
        .post(
          "/wuAPI/vipDcApi/cusPlanH5/getVipUserCourse",
          _this.$qs.stringify({
            "form.reqUid": "64789-vue",
            "form.userId": _this.$route.query.userid,
            "form.channel": "dc2"
          })
        )
        .then(res => {
          window.console.log(res);
          const json = res.data;
          if (json.result === "Y") {
            _this.list = json.entRet.lstVipUser;
          }
        })
        .catch(res => {
          Toast("页面加载失败");
        });
    },
    /**
     * 跳转课程计划日程
     */
    goPlanNum(totalTime, incompleteTime, finishTime, absentSTime) {
      window.location.href =
        "/cjsj/planNum?totalTime=" +
        totalTime +
        "&incompleteTime=" +
        incompleteTime +
        "&finishTime=" +
        finishTime +
        "&absentSTime=" +
        absentSTime;
    }
  }
});
