





























































































import Vue from "vue";
import scroll from "vue-seamless-scroll";
import wxShare from "../../tool/wechat";
import cookie from "vue-cookies";
import { Toast, Popup } from "vant";
Vue.use(Toast).use(Popup);
export default Vue.extend({
  data() {
    return {
      dev: this.$F.dev(),
      randomCd: this.$route.query.randomCd,
      advWxcd: this.$route.query.advWxcd,
      isComplete: false, //是否完成步骤
      copyFlg: 1, //复制状态
      openWeChatTips: false, //打开微信弹出
      loadFlg: false, //加载动画
      popupTexst: "到微信添加朋友页面粘贴微信号并搜索",
      backTips: false //挽留弹出
    };
  },
  created() {
    const _this = this;
    /**
     * 页面加载设置
     */
    this.$bridge.setBridgeIsShowInfo({
      close: "1",
      pull: "0",
      prev: "1",
      noback: "1",
      slip: "1",
      service: "0"
    });
    /**接受客户端数据 */
    (window as any).sendWebMessage = function(val) {
      let obj = JSON.parse(val);
      if (obj.event == "click" && obj.name == "back") {
        let newUserSuccessbackFlg = (cookie as any).get("newUserSuccessbackFlg")
          ? (cookie as any).get("newUserSuccessbackFlg")
          : 0;
        if (newUserSuccessbackFlg != 1 && _this.isComplete == false) {
          _this.backTips = true;
          (cookie as any).set(
            "newUserSuccessbackFlg",
            parseInt(newUserSuccessbackFlg) + 1,
            "1d"
          );
        } else {
          var openObj = {};
          _this.goPlan("11");
        }
      }
    };
    this.$bridge.NativeBridge("editTitle", "");
  },
  methods: {
    /**
     * 去定制
     */
    goPlan(val) {
      this.$bridge.NativeBridge("sendNativeMessage", {
        name: "newUserToPlan"
      });
      this.$openPage.NativeOpenPage(val, "0");
    },
    /**
     * 复制
     */
    copy(val, type) {
      if (type == 1) {
        this.copyFlg = 2;
        this.popupTexst = "到微信添加朋友页面粘贴微信号并搜索";
      } else if (type == 2) {
        this.popupTexst = "添加班长微信时粘贴并发送";
        this.isComplete = true;
      }
      this.openWeChatTips = true;
      var obj = {
        text: val,
        needOpenWexin: "0",
        showPopup: "0"
      };
      this.$bridge.NativeBridge("copyAction", obj, 0);
    },
    /**
     * 返回页面
     */
    back() {
      this.$bridge.NativeBridge("blackAction", {});
    },
    /**
     * 关闭打开微信
     */
    hideOpenWeChat() {
      this.openWeChatTips = false;
    },
    /**
     * 打开微信
     */
    openWechat() {
      this.$openPage.NativeOpenPage("109");
      this.openWeChatTips = false;
    }
  }
});
