






























































































































































import Vue from "vue";
import { Toast, Swipe, SwipeItem, Lazyload, Icon, Popup } from "vant";
Vue.use(Toast)
  .use(Swipe)
  .use(SwipeItem)
  .use(Lazyload)
  .use(Icon)
  .use(Popup);
export default Vue.extend({
  data() {
    return {
      trainid: "4", //课程id
      indicatorIndex: 1, //swipe选中index
      info: {
        lstTargetClasType: [
          {
            periodId: "",
            sprice: "",
            classType: "",
            classTitle: "",
            priceFlg: ""
          }
        ],
        actHeadImg: "",
        actSTimePNumIntr: "",
        entActIntrNew: {},
        verFlg: "0",
        qrImg: "",
        qrHImg: ""
      },
      shareJson: {
        title: "",
        urlImg: "",
        desc: "",
        weiboTitle: "",
        weiboUrl: ""
      }, //分享文案
      iosPayShow: false //ios取消支付弹出
    };
  },
  created() {
    let _this = this;
    _this.trainid = _this.$route.query.trainid + "";
    let shareObj = {
      title: _this.shareJson.title,
      imgUrl: _this.shareJson.urlImg,
      desc: _this.shareJson.desc,
      ctitle: _this.shareJson.title,
      link:
        "https://www.fitapp.com.cn/wx/train?trainid=" +
        _this.$route.query.trainid,
      olapflg: "25",
      display: "1",
      shareList: "1,2"
    };
    _this.getPageInfo();
    if (_this.trainid == "4") {
      (shareObj.title = "点开，你也是小腰精"),
        (shareObj.imgUrl = "https://cdn.fitapp.cn/vue/train/yao.png"),
        (shareObj.desc = "教练指导，14天雕刻3D小蛮腰"),
        (shareObj.ctitle = "点开，你也是小腰精");
    } else if (_this.trainid == "12") {
      (shareObj.title = "练完肚子瘦一圈，进阶迷人腹肌"),
        (shareObj.imgUrl = "https://cdn.fitapp.cn/vue/train/fu.png"),
        (shareObj.desc = "教练指导，14天虐出人鱼线"),
        (shareObj.ctitle = "练完肚子瘦一圈，进阶迷人腹肌");
    } else if (_this.trainid == "14") {
      (shareObj.title = "小腿细长直，看上去高了10cm"),
        (shareObj.imgUrl = "https://cdn.fitapp.cn/vue/train/tui.png"),
        (shareObj.desc = "教练指导，14天全方位瘦腿"),
        (shareObj.ctitle = "小腿细长直，看上去高了10cm");
    }
    /**
     * 右上角分享初始化
     */
    _this.$bridge.setShareInfo(shareObj);
    /**
     * 页面加载设置
     */
    _this.$bridge.setBridgeIsShowInfo({
      close: "1",
      pull: "1",
      prev: "1",
      noback: "0",
      slip: "1",
      service: "0"
    });
  },
  methods: {
    //获取页面数据
    getPageInfo() {
      let _this = this;
      _this.$axios
        .post(
          "/wuAPI/fitDcApi/sptWxH5Xly/getTrainActId4Resource",
          _this.$qs.stringify({
            "form.reqUid": _this.$F.userid() == 1 ? "10092" : _this.$F.userid(),
            "form.trainActId": _this.trainid,
            "form.channel": "dc2",
            "form.phoneType": _this.$B.system() == "ios" ? "1" : "0",
            isVue: "1",
            "form.version": _this.$F.dev(1)
          })
        )
        .then(res => {
          _this.info = res.data.entRet;
          window.document.title = res.data.entRet.actTitleSub2;
          _this.shareJson = JSON.parse(res.data.entRet.shareJson);
          if (_this.$B.browser() == "fit") {
            _this.$bridge.NativeBridge(
              "editTitle",
              res.data.entRet.actTitleSub2
            );
          }
        })
        .catch(res => {
          Toast("页面加载失败");
        });
    },
    /**
     * 打开在线客服
     */
    openCustomService() {
      this.$openPage.NativeOpenPage("3", "xiaoshou");
    },
    /**
     * swipe
     */
    swipeFn(index: any) {
      this.indicatorIndex = index + 1;
    },
    /**
     * 打开我的会员
     */
    goVip() {
      this.$openPage.NativeOpenPage("1");
    },
    /**
     * 保存图片
     */
    savePhoto() {
      const _this = this;
      let obj = {
        url: this.info.qrHImg,
        text: "",
        isOpenWeixin: "0",
        successTipsText: "保存成功" //保存成功提示文案ios
      };
      this.$bridge.NativeBridge("savePhoto", obj);
    },
    /**
     * 获取优惠券
     */
    getCoupon() {
      let _this = this;
      let trainid = _this.$route.query.trainid; //活动id
      _this.$axios
        .post(
          "/wuAPI/fitDcApi/sptWxH5Jzy/getTrainActCouponResource",
          _this.$qs.stringify({
            "form.reqUid": _this.$F.userid(),
            "form.trainActId": trainid,
            "form.channel": "dc2"
          })
        )
        .then(res => {
          if (res.data.result == "Y") {
            Toast(res.data.entSucc.succShowMessage);
            _this.creatOrderFn();
          } else {
            Toast(res.data.entError.errorShowMessage);
          }
        })
        .catch(res => {
          Toast("获取优惠券失败，请联系客服");
        });
    },
    /**
     * 提交订单
     */
    creatOrderFn() {
      //判断游客登录
      if (this.$F.getTourist() == 1) {
        this.$bridge.NativeBridge("touristJumpToLogin");
        return false;
      }

      //验证
      let _this = this;
      //创建订单
      _this.$axios
        .post(
          "/wuAPI/fitDcApi/sptWxH5Xly/modTrainActId4UserReq",
          _this.$qs.stringify({
            "form.channel": "dc2",
            "form.reqUid": _this.$F.userid(),
            "form.strAppointFlg": "0",
            "form.trainActId": _this.trainid,
            "form.periodId": _this.info.lstTargetClasType[0].periodId,
            "form.bprice": _this.info.lstTargetClasType[0].sprice,
            "form.classType": _this.info.lstTargetClasType[0].classType,
            "form.classTitle": _this.info.lstTargetClasType[0].classTitle,
            "form.payType": "0",
            "form.priceFlg": _this.info.lstTargetClasType[0].priceFlg
          })
        )
        .then(res => {
          if (res.data.result == "Y") {
            setTimeout(function() {
              _this.payFn(res.data.entRet);
            }, 1000);
          }
        })
        .catch(res => {
          Toast("页面加载失败");
        });
    },
    /**
     * pay
     */
    payFn(res: any) {
      let _this = this;
      var popGoodsInfo = {
        commodityId: _this.info.lstTargetClasType[0].classType, //商品id
        callType: "2",
        commodityTitle: _this.info.lstTargetClasType[0].classTitle, //商品标题
        commodityComment: _this.info.lstTargetClasType[0].classTitle, //商品描述
        comment: _this.info.lstTargetClasType[0].classTitle, //商品描述
        price: _this.info.lstTargetClasType[0].sprice, //价格
        commodityType: "3",
        buyComment: "",
        buyTitle: _this.info.lstTargetClasType[0].classTitle //商品标题
      };
      var payObj = {
        showPaySheet: "1",
        showApplePay: "0",
        goodsInfo: JSON.stringify(popGoodsInfo),
        cusColor: "#FFFFFF",
        callback: "successFn",
        androidStyleType: "99",
        paySort: "1,0",
        btnColor: "#2AC77D"
      };
      if (_this.$F.dev() >= 643) {
        if (_this.$B.system() == "ios") {
          payObj.showPaySheet = "0";
          payObj.showApplePay = "1";
          payObj.paySort = "";
        }
      }
      //支付
      _this.$bridge.NativeBridge("payPop", payObj);
      //支付成功运行方法
      (window as any).successFn = function(msg: any) {
        msg = JSON.parse(msg);
        if (msg.type == "success") {
          window.location.href =
            "/train/orderDetails?trainid=" +
            _this.trainid +
            "&tactuid=" +
            res.tactuId +
            "&actuid=" +
            res.actuId +
            "&orderid=" +
            msg.orderid;
        } else if (msg.type == "fail") {
          if (
            _this.$B.system() == "ios" &&
            _this.info.verFlg == "1" &&
            _this.$F.dev() >= 643
          ) {
            _this.iosPayShow = true;
            (window as any).sendWebMessage = function(val) {
              window.console.log(val);
              let obj = JSON.parse(val);
              window.console.log(obj.name, obj.json.type);
              if (obj.name == "savePhoto" && obj.json.type == "success") {
                _this.iosPayShow = false;
              }
            };
          }
        }
      };
    }
  }
});
