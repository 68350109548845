







































































































































































































import Vue from "vue";
import { Tab, Tabs } from "vant";
Vue.use(Tab).use(Tabs);
export default Vue.extend({
  data() {
    return {
      activeName: "1211"
    };
  },
  created() {
    this.activeName = this.$route.query.page.toString();
  },
  methods: {
    onChange() {
      document.documentElement.scrollTop = 0;
    }
  }
});
