import { render, staticRenderFns } from "./2020-12-12.vue?vue&type=template&id=f15dc596&scoped=true&"
import script from "./2020-12-12.vue?vue&type=script&lang=ts&"
export * from "./2020-12-12.vue?vue&type=script&lang=ts&"
import style0 from "./2020-12-12.vue?vue&type=style&index=0&id=f15dc596&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f15dc596",
  null
  
)

export default component.exports