









import Vue from "vue";
export default Vue.extend({
  beforeCreate() {
    const url = this.$route.query.url;
    (window as any).location.href = url + "?form.userId=" + this.$F.userid();
  }
});
