






















































































import Vue from "vue";
import { Toast, Lazyload, Popup } from "vant";
const Info = `1.活动时间：2024年12月06日10点-2024年12月15日23点<br />
2. 活动期间，同一用户（同一用户名、设备、手机号都视为同一用户）仅限参与1次，重复购买将不附赠1年会员权益。<br />
3.“独享真人教练陪伴”指真人教练微信端在线一对一服务。<br />
4. 由于Fit VIP会员服务是数字化商品，基于互联网服务的特殊性和国家相关管理规定，如因您的个人原因发生错误购买或无法正常享受会员权益，Fit不能退还相应会员费。<br />
5. Fit保留本次活动的最终解释权。`;
Vue.use(Toast)
  .use(Lazyload)
  .use(Popup);
export default Vue.extend({
  data() {
    return {
      sex: "0",
      browser: this.$B.browser(),
      show: false,
      info: {
        entMeda: {
          lstPlan: []
        },
        lstCom: [
          {
            actPrice: "",
            actTitle: "",
            comFlg: "",
            commodityDesc: "",
            commodityId: "",
            commodityPrice: "",
            commodityTitle: "",
            endDate: "",
            imgUrl: "",
            periodNums: "",
            tarVal: "",
            type: "",
            actFlg: ""
          }
        ]
      },
      ruleShow: false,
      infoText: ""
    };
  },
  created() {
    this.getPageInfo();
    this.infoText = Info;
  },
  methods: {
    /**
     * 获取基础数据
     */
    getPageInfo() {
      const _this = this;
      _this.$axios
        .post(
          "/wuAPI/fitDcApi/fitH5Act/getGrpActComMcResource",
          _this.$qs.stringify({
            "form.reqUid": "64789-vue",
            "form.userId": _this.$F.userid() == 1 ? "10092" : _this.$F.userid(),
            "form.channel": "dc2",
            "form.mbType": _this.$B.system() == "ios" ? "1" : "0",
            "form.grpType": "210809ACT",
            "form.version": _this.$F.dev(1)
          })
        )
        .then(res => {
          window.console.log(res);
          if (res.data.result == "Y") {
            _this.info = res.data.entRet;
          } else {
            Toast("页面加载失败");
          }
        })
        .catch(res => {
          Toast("页面加载失败");
        });
    },
    /**
     * 打开在线客服
     */
    openCustomService() {
      if (this.browser == "wechat") {
        this.goFit();
        return false;
      }
      this.$openPage.NativeOpenPage("3", "xiaoshou");
    },
    /**
     * 微信打开活动页面openFit
     */
    goFit() {
      this.$openPage.openFit("1", {
        url: this.$F.getOrigin() + "/subject/2021-12-12"
      });
    },
    /**
     * 打开专题
     */
    goSubject(id) {
      window.console.log(id);
      this.$openPage.NativeOpenPage("13", id);
    },
    /**
     * 打开课程详情
     */
    goTrain(id) {
      window.console.log(id);
      this.$openPage.NativeOpenPage("7", id);
    },
    /**
     * 支付
     */
    payFn(index) {
      const _this = this;

      if (_this.info.lstCom[index].actFlg == "-2") {
        Toast("活动已结束");
        return false;
      }

      if (_this.$F.dev() >= 620) {
        const popGoodsInfo = {
          commodityId: _this.info.lstCom[index].commodityId, //商品id
          callType: "2",
          commodityTitle: _this.info.lstCom[index].commodityTitle, //商品标题
          commodityComment: _this.info.lstCom[index].commodityDesc, //商品描述
          comment: _this.info.lstCom[index].commodityDesc, //商品描述
          price: _this.info.lstCom[index].commodityPrice, //价格
          commodityType: "3",
          buyComment: _this.info.lstCom[index].commodityDesc,
          buyTitle: _this.info.lstCom[index].commodityTitle //商品标题
        };
        let payObj = {
          showPaySheet: "1",
          showApplePay: "0",
          goodsInfo: JSON.stringify(popGoodsInfo),
          cusColor: "#DBB76A",
          callback: "successFn",
          androidStyleType: "15",
          paySort: "1,0"
        };
        if (_this.$F.dev() >= 635 && _this.$B.system() == "ios") {
          payObj.showPaySheet = "0";
          payObj.showApplePay = "1";
          payObj.paySort = "";
        }
        //支付
        _this.$bridge.NativeBridge("payPop", payObj);
        (window as any).successFn = function(msg) {
          msg = JSON.parse(msg);
          if (msg.type == "success") {
            _this.$bridge.NativeBridge("sendNativeMessage", {
              name: "energyExchange"
            });
            _this.getPageInfo();
          }
        };
      }
    }
  }
});
