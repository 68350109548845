























































import Vue from "vue";
import { Toast, Lazyload, Popup } from "vant";
const infoText = [
  `
<dt>使用流程</dt>
<dd>成功邀请3位好友助力后，系统将自动发放286F币（价值268元）至您的账户中</dd>
<dt>使用规则</dt>
<dd>1.点击底部【发现页】—【大师课堂】—选择相应价值大师课堂—【加入学习】—【F币支付】 </dd>
<dd>2.大师课堂一经兑换，不可退回，请慎重考虑。 </dd>
`,
  `
<dt>使用流程</dt>
<dd>点击复制兑换码，超级训练营兑换有效期自领取后的60天内可以进行兑换使用，可免费兑换指定超级训练营—14天细腰马甲线冲刺计划</dd>
`,
  `
<dt>使用流程</dt>
<dd>1.点击进入链接→输入手机号及验证码，获得免费名额</dd>
<dd>2.关注“善诊”公众号→完成在线健康评估，免费领取定制体检</dd>
<dd>3.选择体检医院、机构→基础检查项0元，勾选其他项目享优惠价→点击去下单，在公众号预约时间即可，体检当天请携带身份证前往。</dd>
<dd>4.权益数量有限，兑完即止。</dd>
<dt>使用规则</dt>
<dd>1.福利包含 399元体检套餐及健康评估； </dd>
<dd>2.体检人满足18周岁以上，服务覆盖全国200余城，360+医院及品牌机构； </dd>
<dd>3.每个手机号限领一次，在领取后两个月内完成预约； </dd>
<dd>4.活动有效期至2020年12月31日； </dd>
<dd>5.活动福利由健康管理服务平台善诊提供，参与过程中有任何疑问请拨打善诊客服电话：400-6762-960（7:30-20:00）</dd>
`,
  `
<dt>使用流程</dt>
<dd>1.进入领取页面，输入手机号码，点击【立即领取】；</dd>
<dd>2.领取成功后，礼包自动加入【青桔账户车】；</dd>
<dd>3.骑行券在单次骑行，结算时自动抵扣使用；购卡折扣券，在购买青桔单车/青桔电单车卡类产品时，自动抵扣使用；</dd>
权益数量有限，兑完即止。</dd>
<dt>使用规则</dt>
<dd>1.内含骑行券或折扣券，领取后7日内有效；</dd>
<dd>2.每个用户活动期间可领取一次，同一手机号视为同一用户；</dd>
<dd>3.每次用车只能使用一张优惠券，一天内没有张数使用限制。优惠券使用需遵守平台优惠券规则；</dd>
<dd>4.可在「青桔骑行小程序 - 我的 - 我的钱包 - 优惠券」中查看券明细；</dd>
`,
  `
<dt>使用流程</dt>
<dd>1.点击兑换，进入活动页面</dd>
<dd>2.点击领取按钮，登录或注册哔哩哔哩，提示领取成功</dd>
<dd>3.进入哔哩哔哩漫画APP—选择漫画某话付费章节—提示限免卡可用，点击“立即使用”—解锁成功，3天内免费阅读</dd>
<dd>4.权益数量有限，兑完即止。</dd>
<dt>使用规则</dt>
<dd>1.限免卡自领取后7天内有效，请在有效期内使用</dd>
<dd>2.每人10张，每人仅限领取一次，总量无上限</dd>
<dd>3.张限免卡可解锁一话付费章节3天（存在部分章节不能使用限免卡的情况） </dd>
<dd>4.如有疑问可随时咨询在线客服，点击我的—帮助中心—联系客服</dd>
`,
  `
<dt>使用流程</dt>
<dd>1.点击领取券码,登录或注册懒人听书APP</dd>
<dd>2.注册并登陆后在【账号】>【VIP会员】界面最下方点击【使用兑换码兑换会员】输入兑换码完成兑换,返回刷新即可查看会员到期时间</dd>
<dd>3.权益数量有限，兑完即止。</dd>
权益数量有限，兑完即止。</dd>
<dt>使用规则</dt>
<dd>1.仅限兑换使用一次</dd>
<dd>2.活动时间至2021年10月13日,兑使后使用期限为30天,请尽快兑换使用</dd>
<dd>3.此方式开通的VIP会员无每月赠送听读券权益享受,付费精品、阅读书籍中的付费章节VIP会员享9折购买  </dd>
<dd>4.懒人听书客服热线:0755-36881755</dd>
`,
  `
<dt>使用流程</dt>
<dd>1.兑换有效期：2020年12月31日之前兑换有效</dd>
<dd>2.兑换范围：仅限上海地区（不包括上海崇明）</dd>
<dd>3.权益数量有限，兑完即止。</dd>
<dt>使用规则</dt>
<dd>1.下载天天果园APP→进入天天果园APP→点击“我的果园”进入→点击“鹿小天会员 尊享会员特权”进入→点击“开通鹿小天”进入→在鹿小天会员券卡兑换里输入16位兑换码点击立即验证→点一下已阅读并同意《天天果园鹿小天会员—同意协议》再点击立即兑换即可成为鹿小天会员 </dd>
<dd>2.如果已是鹿小天会员用户，使用兑换码，会员有效期会延后一个月 </dd>
<dd>3.如有疑问，欢迎通过天天果园APP，微信服务号或者官网联系果园在线客服处理。 </dd>
`
];
Vue.use(Toast)
  .use(Lazyload)
  .use(Popup);
export default Vue.extend({
  data() {
    return {
      show: false,
      infotext: "",
      info: {
        benefitsFith: "",
        benefitsFoth: "",
        benefitsFst: "",
        benefitsScd: "",
        benefitsSevth: "",
        benefitsSix: "",
        benefitsThd: ""
      }
    };
  },
  created() {
    this.getPageInfo();
  },
  methods: {
    /**
     * 获取基础数据
     */
    getPageInfo() {
      const _this = this;
      (_this as any).renewFlg = _this.$route.query.renew ? true : false; // 续费状态
      let trainid = _this.$route.query.trainid; //活动id
      _this.$axios
        .post(
          "/wuAPI/fitDcApi/fitH5Act2020/get20Double11ActRedmRes",
          _this.$qs.stringify({
            "form.reqUid": "64789-vue",
            "form.mbType": _this.$B.system() == "ios" ? "1" : "0",
            "form.channel": "dc2",
            "form.userId": _this.$F.userid() == 1 ? "10092" : _this.$F.userid()
          })
        )
        .then(res => {
          if (res.data.result == "Y") {
            _this.info = res.data.entRet;
          }
        })
        .catch(res => {
          Toast("页面加载失败");
        });
    },
    showInfo(num: number) {
      this.show = true;
      this.infotext = infoText[num];
    },
    /**
     * 复制打开微信
     */
    copyFn(type, val) {
      window.console.log(type, val);
      if (type != 1) {
        var obj = {
          text: val,
          needOpenWexin: "0"
        };
        this.$bridge.NativeBridge("copyAction", obj, 0);
      }

      if (type == 1) {
        this.$openPage.NativeOpenPage("9");
      } else if (type == 2) {
        this.$openPage.NativeOpenPage("10");
      }
    }
  }
});
