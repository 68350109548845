




































































































































































































































































































import Vue from "vue";
import {
  Toast,
  Icon,
  Popup,
  DatetimePicker,
  Tab,
  Tabs,
  Search,
  Dialog,
  Switch
} from "vant";
import dayjs from "dayjs";
Vue.use(DatetimePicker)
  .use(Toast)
  .use(Icon)
  .use(Popup)
  .use(Tab)
  .use(Tabs)
  .use(Search)
  .use(Dialog)
  .use(Switch);
export default Vue.extend({
  data() {
    return {
      info: {
        getCusPlanEntity: {
          coachNm: "",
          strengNm: "",
          strengFlg: "",
          duration: "",
          coachId: "",
          planId: "",
          planNm: "",
          formalType: "",
          classDate: "",
          courseTime: "",
          assessment: "",
          stageFlg: "0",
          stagePid: "",
          stagePNm: ""
        }
      },
      isOpenAssessment: false, //是否打开评估课
      value: "",
      showPicker: false, //选择上课是时间
      showCoach: false, //选择教练
      showStrength: false, //选择上课强度
      minDate: new Date(2000, 0, 1),
      maxDate: new Date(2050, 10, 1),
      currentDate: new Date(),
      timeValue: "请选择开始时间",
      lstStrengthVal: [""], //强度
      lstStrengthId: [""], //强度id
      lstStrengthIndex: 0, //强度默认索引
      lstCoachVal: [""], //教练
      lstCoachId: [""], //教练id
      lstCoachIndex: 0, //强度默认索引
      changeFlg: false, //修改课程
      searchFlg: false, //搜索
      isSearchFlg: false, //当前是否搜索过
      searchHistory: [], //搜索历史
      searchHistoryFlg: false, //历史记录状态
      planList: {
        courseNum: "",
        lstDefault: [],
        lstDegree: [],
        lstVipPlan: []
      },
      searchData: {
        //搜索数据
        courseNum: "",
        lstDefault: [],
        lstDegree: [],
        lstVipPlan: []
      },
      selectPlanId: "", //选中的课程id
      selectPlanName: "", //选中的课程
      onloadFlg: false,
      isChangeFlg: false, //是否修改
      checked: false, //是否同步约课限制
      isUpload: true //是否在提交数据
    };
  },
  updated() {
    this.onloadFlg = true;
  },
  created() {
    const _this = this;
    _this.getUserInfo();
    _this.getHistory();
    /**接受客户端数据 */
    (window as any).sendWebMessage = function(val) {
      window.console.log(val);
      let obj = JSON.parse(val);
      if (obj.event == "click" && obj.name == "back") {
        _this.backDialog();
      }
    };
    _this.$bridge.NativeBridge("editTitle", _this.$route.query.courseTypeNm);
  },
  methods: {
    formatter(type, val) {
      if (type === "year") {
        return val + "年";
      }
      if (type === "month") {
        return val + "月";
      }
      if (type === "day") {
        return val + "日";
      }
      if (type === "hour") {
        return val + "时";
      }
      if (type === "minute") {
        return val + "分";
      }
      return val;
    },
    onConfirm(value) {
      this.timeValue = dayjs(value)
        .locale("zh-cn")
        .format("YYYY-MM-DD HH:mm:ss");
      this.showPicker = false;
    },
    onCancel() {
      this.showPicker = false;
    },
    onCancelCoach() {
      this.showCoach = false;
    },
    onCancelStrength() {
      this.showStrength = false;
    },
    onSearch(val) {
      let searchHistoryArr: any =
        JSON.parse((localStorage as any).getItem("searchHistory")) != null
          ? JSON.parse((localStorage as any).getItem("searchHistory"))
          : [];
      searchHistoryArr.push(val);
      searchHistoryArr = [...new Set(searchHistoryArr)];
      localStorage.setItem("searchHistory", JSON.stringify(searchHistoryArr));
      this.value = "";
      this.searchHistory = searchHistoryArr;
      this.isSearchFlg = true;

      this.getPlanList(
        this.isOpenAssessment ? "13" : this.info.getCusPlanEntity.formalType,
        val,
        "99",
        true
      );
    },
    backDialog() {
      const _this = this;
      if (_this.isChangeFlg) {
        Dialog.confirm({
          title: "提示",
          message: "当前更改还未保存，确定放弃吗？",
          confirmButtonText: "再想想",
          cancelButtonText: "放弃"
        })
          .then(() => {})
          .catch(() => {
            _this.back();
          });
      } else {
        _this.back();
      }
    },
    /**
     * 获取搜索历史
     */
    getHistory() {
      let searchHistoryArr: any = (localStorage as any).getItem("searchHistory")
        ? JSON.parse((localStorage as any).getItem("searchHistory"))
        : [];
      this.searchHistory = searchHistoryArr;
    },
    /**
     * 返回页面
     */
    back() {
      this.$bridge.NativeBridge("blackAction", {});
    },
    /**
     * 清楚历史
     */
    clearHistory(index) {
      if (index == "all") {
        localStorage.removeItem("searchHistory");
        this.searchHistory = [];
      } else {
        let searchHistoryArr: any = JSON.parse(
          (localStorage as any).getItem("searchHistory")
        );
        searchHistoryArr.splice(index, 1);
        (localStorage as any).setItem(
          "searchHistory",
          JSON.stringify(searchHistoryArr)
        );
        this.searchHistory = searchHistoryArr;
      }
      this.searchHistoryFlg = false;
    },
    onSearchCancel() {
      this.searchFlg = false;
    },
    /**
     * 获取用户数据
     */
    getUserInfo(week?) {
      let _this = this;
      _this.$axios
        .post(
          "/wuAPI/vipDcApi/cusPlanH5/getCusPlanCourse",
          _this.$qs.stringify({
            "form.reqUid": "64789-vue",
            "form.userId": _this.$F.userid(),
            "form.channel": "dc2",
            "form.coursetimeId": _this.$route.query.coursetimeId
          })
        )
        .then(res => {
          window.console.log(res);
          const json = res.data;
          _this.info = json.entRet;
          _this.lstStrengthVal = [];
          _this.lstStrengthId = [];
          _this.timeValue = _this.info.getCusPlanEntity.classDate
            ? _this.info.getCusPlanEntity.classDate
            : "请选择开始时间";
          json.entRet.lstStrength.forEach((item, index) => {
            _this.lstStrengthVal.push(item.strengNm);
            _this.lstStrengthId.push(item.strengFlg);
            if (item.strengFlg == _this.info.getCusPlanEntity.strengFlg) {
              _this.lstStrengthIndex = index;
            }
          });
          _this.lstCoachVal = [];
          _this.lstCoachId = [];
          json.entRet.lstCoach.forEach((item, index) => {
            _this.lstCoachVal.push(item.coachNm);
            _this.lstCoachId.push(item.coachId);
            if (item.coachId == _this.info.getCusPlanEntity.coachId) {
              _this.lstCoachIndex = index;
            }
          });
          if (!_this.info.getCusPlanEntity.stagePNm) {
            _this.info.getCusPlanEntity.stagePNm = "请选择阶段性评估课";
          }
        })
        .catch(res => {
          Toast("页面加载失败");
        });
    },
    /**
     * 获取课程列表
     */
    getPlanList(formalType, title?, cusDegree = "99", isSearch?) {
      let _this = this;
      _this.$axios
        .post(
          "/wuAPI/vipDcApi/cusPlanH5/getVipCourse",
          _this.$qs.stringify({
            "form.reqUid": "64789-vue",
            "form.userId": _this.$F.userid(),
            "form.channel": "dc2",
            "form.formalType": formalType,
            "form.title": title,
            "form.cusDegree": cusDegree,
            "form.searchFlg": title ? "1" : "0"
          })
        )
        .then(res => {
          const json = res.data;
          if (isSearch) {
            _this.searchData = json.entRet;
            this.searchHistoryFlg = false;
          } else {
            _this.planList = json.entRet;
          }
          this.changeFlg = true;
        })
        .catch(res => {
          Toast("页面加载失败");
        });
    },
    /**
     * 打开课程
     */
    showPlan(val) {
      /**
       * 获取课程数据
       */
      this.isOpenAssessment = val == 13 ? true : false;
      this.getPlanList(val);
    },
    /**
     * 选择教练
     */
    onConfirmCoach() {
      const index =
        parseInt((this as any).$refs.coachPicker.getIndexes().toString()) + 1;
      this.showCoach = false;
      this.info.getCusPlanEntity.coachNm = this.lstCoachVal[index - 1];
      this.info.getCusPlanEntity.coachId = this.lstCoachId[index - 1];
    },
    /**
     * 选择强度
     */
    onConfirmStrength() {
      const index =
        parseInt((this as any).$refs.strengthPicker.getIndexes().toString()) +
        1;
      this.showStrength = false;
      this.info.getCusPlanEntity.strengNm = this.lstStrengthVal[index - 1];
      this.info.getCusPlanEntity.strengFlg = this.lstStrengthId[index - 1];
    },
    /**
     * 修改分钟
     */
    changeM(m) {
      this.info.getCusPlanEntity.duration = m;
    },
    /**
     * 修改评估
     */
    changeAssessment(val) {
      this.info.getCusPlanEntity.stageFlg = val;
      if (val == 1) {
        this.info.getCusPlanEntity.duration = "60";
      }
    },
    /**
     * 选中课程
     */
    selectPlanFn(courseId, courseNm) {
      window.console.log(4124332, courseId, courseNm);
      if (this.isOpenAssessment) {
        this.info.getCusPlanEntity.stagePid = courseId;
        this.info.getCusPlanEntity.stagePNm = courseNm;
      } else {
        this.info.getCusPlanEntity.planId = courseId;
        this.info.getCusPlanEntity.planNm = courseNm;
      }
    },
    /**
     * 修改课程
     */
    changePlanFn() {
      this.changeFlg = false;
      this.searchFlg = false;
      this.searchData.lstVipPlan = [];
      this.isSearchFlg = false;
    },
    /**
     * 显示历史记录
     */
    onFocus() {
      //searchHistory: [], //搜索历史
      //searchHistoryFlg:false, //历史记录状态
      if (this.searchHistory.length > 0) {
        this.searchHistoryFlg = true;
      }
    },
    upPlanData() {
      let _this = this;
      if (_this.timeValue == "请选择开始时间") {
        Toast("请选择开始时间");
        return false;
      }

      if (this.checked) {
        let time: any =
          _this.timeValue.indexOf("日") == -1
            ? _this.timeValue
            : _this.info.getCusPlanEntity.courseTime;
        if (time) {
          time = time.split(" ")[1];
          time = time.split(":");
          if ((time[1] == "00" || time[1] == "15") && time[2] == "00") {
            window.console.log(12);
          } else {
            Toast("课程开始时间需为整点和15分");
            return false;
          }
        }

        if (
          (time[1] != "00" || time[2] != "00") &&
          this.info.getCusPlanEntity.stageFlg == "1"
        ) {
          Toast("阶段性评估课开始时间需为整点");
          return false;
        }
      }

      if (!_this.info.getCusPlanEntity.planId) {
        Toast("请选择课程");
        return false;
      }

      if (
        _this.info.getCusPlanEntity.stageFlg == "1" &&
        !_this.info.getCusPlanEntity.stagePid
      ) {
        Toast("请选择评估课件");
        return false;
      }

      if (_this.isUpload) {
        _this.isUpload = false;
        _this.$axios
          .post(
            "/wuAPI/vipDcApi/cusPlanH5/updCusPlanCourse",
            _this.$qs.stringify({
              "form.reqUid": "64789-vue",
              "form.userId": _this.$F.userid(),
              "form.channel": "H5",
              "form.coursetimeId": _this.$route.query.coursetimeId,
              "form.duration": _this.info.getCusPlanEntity.duration,
              "form.courseTime":
                _this.timeValue.indexOf("日") == -1
                  ? _this.timeValue
                  : _this.info.getCusPlanEntity.courseTime,
              "form.coachId": _this.info.getCusPlanEntity.coachId,
              "form.planId": _this.info.getCusPlanEntity.planId,
              "form.strengFlg": _this.info.getCusPlanEntity.strengFlg,
              "form.stagePid":
                _this.info.getCusPlanEntity.stageFlg != "0"
                  ? _this.info.getCusPlanEntity.stagePid
                  : "",
              "form.stageFlg": _this.info.getCusPlanEntity.stageFlg,
              "form.clientChk": _this.checked ? 1 : 0
            })
          )
          .then(res => {
            const json = res.data;
            if (json.result == "Y") {
              Toast("编辑成功");
              setTimeout(function() {
                _this.back();
              }, 2000);
            } else {
              Toast(json.entError.errorShowMessage);
            }
            _this.isUpload = true;
          })
          .catch(res => {
            Toast("页面加载失败");
            _this.isUpload = true;
          });
      }
    },
    /**
     * 是否修改
     */
    isChange() {
      if (this.onloadFlg) {
        this.isChangeFlg = true;
      }
    }
  },
  watch: {
    "info.getCusPlanEntity.duration": "isChange",
    "info.getCusPlanEntity.courseTime": "isChange",
    "info.getCusPlanEntity.coachId": "isChange",
    "info.getCusPlanEntity.planId": "isChange",
    "info.getCusPlanEntity.strengFlg": "isChange",
    "info.getCusPlanEntity.stagePid": "isChange",
    "info.getCusPlanEntity.stageFlg": "isChange"
  }
});
