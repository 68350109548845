var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg"},[_c('div',{staticClass:"head"},[_c('img',{attrs:{"src":"https://img.fitapp.cn/fit/vip/2025/newyear.jpg"}}),_c('img',{staticStyle:{"margin-top":"3px"},attrs:{"src":"https://img.fitapp.cn/fit/vip/2025/202502.png"}}),_c('div',{staticClass:"btn"},[(
          (_vm.info.lstCom[0].comFlg == 0 && _vm.info.lstCom[0].actFlg == 1) ||
            (_vm.info.lstCom[0].comFlg == 0 && _vm.info.lstCom[0].actFlg == -2)
        )?_c('img',{staticClass:"pulse",attrs:{"src":"https://cdn.fitapp.cn/vue/subject/2022-1-1/btn1_1@3x.png","alt":""},on:{"click":function($event){return _vm.payFn(0)}}}):_vm._e(),(_vm.info.lstCom[0].comFlg == 1)?_c('img',{attrs:{"src":"https://cdn.fitapp.cn/vue/subject/2022-1-1/btn1_2@3x.png","alt":""}}):_vm._e()])]),_c('img',{staticStyle:{"margin-top":"-2px"},attrs:{"src":"https://cdn.fitapp.cn/vue/subject/2022-1-1/h5-p2@3x.png","alt":""}}),_c('div',{staticClass:"make"},[_c('ul',_vm._l((_vm.info.entMeda.lstPlan),function(item,index){return _c('li',{key:index,on:{"click":function($event){item.topicFlg == '0'
            ? _vm.goTrain(item.targetId)
            : _vm.goSubject(item.targetId)}}},[_c('img',{attrs:{"src":item.targetImgLnk}}),_c('div',{staticClass:"make-bg"},[_c('p',[_vm._v(_vm._s(item.targetNm))]),_c('strong',[_vm._v(_vm._s(item.joinNum)+"人已参加")]),_vm._m(0,true)])])}),0)]),_vm._m(1),_c('div',{staticClass:"foot-hd"}),_c('div',{staticClass:"foot"},[(
        (_vm.info.lstCom[0].comFlg == 0 && _vm.info.lstCom[0].actFlg == 1) ||
          (_vm.info.lstCom[0].comFlg == 0 && _vm.info.lstCom[0].actFlg == -2)
      )?_c('div',{staticClass:"foot-btn",on:{"click":function($event){return _vm.payFn(0)}}},[_vm._v(" 立即开通年会员 ")]):_vm._e(),(_vm.info.lstCom[0].comFlg == 1)?_c('div',{staticClass:"foot-btn foot-btn-no"},[_vm._v(" 已开通 ")]):_vm._e()]),_c('van-popup',{staticClass:"popup-box",attrs:{"position":"bottom"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('div',{staticClass:"popup-tt"},[_vm._v("活动规则")]),_c('div',{staticClass:"popup-info",domProps:{"innerHTML":_vm._s(_vm.infoText)}})])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('img',{attrs:{"src":"https://cdn.fitapp.cn/vue/subject/2021-12-12/icon.png","alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rule"},[_vm._v(" 活动规则："),_c('br'),_vm._v(" 1. 活动时间：2025年1月27日10点 - 2025年2月28日24点"),_c('br'),_vm._v(" 2. 活动期间，同一用户（同一用户名、设备、手机号都视为同一用户）仅限参与1次，重复购买将不附赠1年会员权益。"),_c('br'),_vm._v(" 3.“独享真人教练陪伴”指真人教练微信端在线一对一服务。"),_c('br'),_vm._v(" 4. 由于Fit VIP会员服务是数字化商品，基于互联网服务的特殊性和国家相关管理规定，如因您的个人原因发生错误购买或无法正常享受会员权益，Fit不能退还相应会员费。"),_c('br'),_vm._v(" 5. Fit保留本次活动的最终解释权。 ")])}]

export { render, staticRenderFns }