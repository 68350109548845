



























import Vue from "vue";
import { Swipe, SwipeItem, Toast, Popup, Icon } from "vant";
Vue.use(Swipe)
  .use(SwipeItem)
  .use(Toast)
  .use(Icon)
  .use(Popup);
export default Vue.extend({
  data() {
    return {
      code: "",
      ruleShow: false
    };
  },
  created() {
    this.code = this.$route.query.code.toString();
  },
  methods: {
    /**
     * 复制打开微信
     */
    copyFn() {
      var obj = {
        text: this.code,
        needOpenWexin: "0",
        successTipsText: "复制成功"
      };
      if (this.$B.system() == "ios") {
        Toast("复制成功");
      }
      this.$bridge.NativeBridge("copyAction", obj, 0);
    }
  }
});
