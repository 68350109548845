

















































































































import Vue from "vue";
import { Swipe, SwipeItem, Toast } from "vant";
import wxShare from "../../tool/wechat";
Vue.use(Swipe)
  .use(SwipeItem)
  .use(Toast);
export default Vue.extend({
  mixins: [wxShare],
  data() {
    return {
      giftFlg: true,
      info: {
        actFlg: "-1",
        entMeda: {
          lstPlan: []
        },
        lstCom: [
          {
            userFlg: 0,
            endDate: "",
            targetId: "",
            commodityId: "",
            commodityTitle: "",
            commodityDesc: "",
            commodityPrice: ""
          },
          {
            userFlg: 0,
            targetId: "",
            endDate: "",
            commodityId: "",
            commodityTitle: "",
            commodityDesc: "",
            commodityPrice: ""
          }
        ]
      }
    };
  },
  created() {
    let _this = this;
    _this.getPageInfo();
    /**
     * 设置微信分享
     */
    if (this.$B.browser() == "wechat") {
      this.setShare();
      return false;
    }
    /**
     * 右上角分享初始化
     */
    _this.$bridge.setShareInfo({
      title: "运动GIRL，请查收！",
      link: this.$F.getOrigin() + "/subject/2021-3-8",
      imgUrl: "https://cdn.fitapp.cn/vue/subject/2021-3-8/share.jpg",
      desc: "百元奖励，助你练得漂亮",
      ctitle: "运动GIRL，请查收！",
      olapflg: "25",
      display: "1",
      shareList: "1,2"
    });
    /**
     * 页面加载设置
     */
    _this.$bridge.setBridgeIsShowInfo({
      close: "1",
      pull: "0",
      prev: "1",
      noback: "0",
      slip: "1",
      service: "0"
    });
    /**接受客户端数据 */
    (window as any).sendWebMessage = function(val) {
      window.console.log(val);
    };
  },
  methods: {
    /**
     * 获取基础数据
     */
    getPageInfo(reqFlg = 1) {
      const _this = this;
      _this.$axios
        .post(
          "/wuAPI/fitDcApi/fitH5Act/getGrpActComMcResource",
          _this.$qs.stringify({
            "form.reqUid": "64789-vue",
            "form.userId": _this.$F.userid() == 1 ? "10092" : _this.$F.userid(),
            "form.channel": "dc2",
            "form.mbType": _this.$B.system() == "ios" ? "1" : "0",
            "form.grpType": "210308ACT",
            "form.reqFlg": reqFlg
          })
        )
        .then(res => {
          if (res.data.result == "Y") {
            let entRet = res.data.entRet;
            _this.info = entRet;
            if (entRet.lstCom.length == 0) {
              _this.giftFlg = true;
            } else {
              _this.giftFlg = false;
            }
          } else {
            Toast("页面加载失败");
          }
        })
        .catch(res => {
          Toast("页面加载失败");
        });
    },
    /**
     * 微信分享
     */
    setShare() {
      const shareInfo = {
        title: `运动GIRL，请查收！`,
        desc: `百元奖励，助你练得漂亮`,
        link: this.$F.getOrigin() + "/subject/2021-3-8",
        img: "https://cdn.fitapp.cn/vue/subject/2021-3-8/share.jpg"
      };
      (this as any).wechatShare(shareInfo);
    },
    /**
     * 微信打开活动页面openFit
     */
    goFit() {
      this.$openPage.openFit("1", {
        url: this.$F.getOrigin() + "/subject/2021-3-8"
      });
    },
    /**
     * 开始
     */
    startFn() {
      if (this.$B.browser() != "fit") {
        this.goFit();
        return false;
      }
      if (this.info.actFlg == "-2") {
        Toast("活动已结束");
        return false;
      } else if (this.info.actFlg == "-1") {
        Toast("活动未开始");
        return false;
      }
      (window as any).gio("track", "web_bnt_click", {
        target_module: "开启女王专属礼包",
        target_title: "动出女王每一面",
        target_id: this.$F.userid()
      });
      this.getPageInfo(2);
    },
    /**
     * 打开课程专题
     */
    goTrain(id) {
      if (this.$B.browser() != "fit") {
        this.goFit();
        return false;
      }
      if (!id) {
        return false;
      }
      this.$openPage.NativeOpenPage("13", id);
    },
    /**
     * 跳转会员中心
     */
    goVip() {
      this.$openPage.NativeOpenPage("1");
    },
    /**
     * 复制打开微信
     */
    copyFn() {
      var obj = {
        text: this.info.lstCom[1].targetId,
        needOpenWexin: "0"
      };
      this.$bridge.NativeBridge("copyAction", obj, 0);
      this.$openPage.NativeOpenPage("10");
    },
    /**
     * 支付
     */
    payFn(index) {
      const _this = this;
      if (_this.$F.dev() >= 620) {
        const popGoodsInfo = {
          commodityId: _this.info.lstCom[index].commodityId, //商品id
          callType: "2",
          commodityTitle: _this.info.lstCom[index].commodityTitle, //商品标题
          commodityComment: _this.info.lstCom[index].commodityDesc, //商品描述
          comment: _this.info.lstCom[index].commodityDesc, //商品描述
          price: _this.info.lstCom[index].commodityPrice, //价格
          commodityType: "3",
          buyComment: _this.info.lstCom[index].commodityDesc,
          buyTitle: _this.info.lstCom[index].commodityTitle //商品标题
        };
        let payObj = {
          showPaySheet: "1",
          showApplePay: "0",
          goodsInfo: JSON.stringify(popGoodsInfo),
          cusColor: "#DBB76A",
          callback: "successFn",
          androidStyleType: "15",
          paySort: "1,0"
        };
        //支付
        _this.$bridge.NativeBridge("payPop", payObj);
        (window as any).successFn = function(msg) {
          msg = JSON.parse(msg);
          if (msg.type == "success") {
            _this.info.lstCom[index].userFlg = 1;
            (window as any).gio("track", "web_bnt_click", {
              target_module: index == 0 ? "会员支付成功" : "细腰马甲支付成功",
              target_title: "动出女王每一面",
              target_id: _this.$F.userid()
            });
            _this.$bridge.NativeBridge("sendNativeMessage", {
              name: "energyExchange"
            });
            _this.getPageInfo();
          }
        };
      }
      (window as any).gio("track", "web_bnt_click", {
        target_module: index == 0 ? "购买会员" : "购买会员细腰马甲",
        target_title: "动出女王每一面",
        target_id: _this.$F.userid()
      });
    }
  }
});
