
























































































































































































































import Vue from "vue";
import scroll from "vue-seamless-scroll";
import wxShare from "../../tool/wechat";
import cookie from "vue-cookies";
import { Toast, Lazyload, Popup, Overlay, Dialog } from "vant";
Vue.use(Toast)
  .use(Lazyload)
  .use(Popup)
  .use(Overlay)
  .use(Dialog);
export default Vue.extend({
  mixins: [wxShare],
  data() {
    return {
      goTopFlg: false, //返回顶部是否漂浮
      actFlg: "0", //是否已购买
      mallFixed: false, //购买按钮是否漂浮
      browser: this.$B.browser(), //浏览器
      system: this.$B.system(), //系统
      leaveShow: false, //挽留弹出
      transitionName: "van-slide-down",
      info: {
        andComId: "",
        entMeda: [],
        comdDesc: "",
        commodityDesc: "",
        goodsGrpId: "",
        lstAct0GrpComData: [
          {
            imgBlnk: "",
            grpTitle: "",
            activityPrice: "",
            comdDesc: "",
            comId: "",
            buyFlg: ""
          }
        ]
      },
      userImg: "", //购买会员用户头像
      userName: "", //购买会员用户名称
      userList: [],
      commontlist: [
        {
          uaerName: "周*瑜",
          imgUrl: "https://cdn.fitapp.cn/vue/vip/7-7/0vip_avatar_01.png",
          text:
            "不得不说，Fit是慢慢的让你喜欢上他，对于我这种锻炼新手，又想动作简单也可以消耗，这个app的课程很适合我。"
        },
        {
          uaerName: "WW*镧",
          imgUrl: "https://cdn.fitapp.cn/vue/vip/7-7/0vip_avatar_02.png",
          text:
            "健身初级人员非常适合，各种类型可选择，也可以根据自己情况调整难度。"
        },
        {
          uaerName: "婉儿*勇敢",
          imgUrl: "https://cdn.fitapp.cn/vue/vip/7-7/0vip_avatar_03.png",
          text:
            "唯一一个很长很长时间一直坚持用的软件，特别喜欢，教练动作示范特别清晰，课程设计非常好，真的一生推！"
        },
        {
          uaerName: "Jags *e",
          imgUrl: "https://cdn.fitapp.cn/vue/vip/7-7/0vip_avatar_04.png",
          text:
            "这个软件太好用了，适合上班族，没太多时间去锻炼，但是每天总能抽出十几二十分钟的那种，用了一个月之后都说我明显瘦了。"
        }
      ],
      commontlist2: [
        {
          uaerName: "SL* na",
          imgUrl: "https://cdn.fitapp.cn/vue/vip/7-7/0vip_avatar_05.png",
          text:
            "对于想练又没有方法的人来说，特别是定制课程，我觉得真的很有用，相当于大半个健身教练了！"
        },
        {
          uaerName: "郭*杰",
          imgUrl: "https://cdn.fitapp.cn/vue/vip/7-7/0vip_avatar_06.png",
          text:
            "有初级入门课程，制定完整的计划，帮助我塑造完美曲线，感谢这款产品，让我有拉动力。"
        },
        {
          uaerName: "芳妮宝贝",
          imgUrl: "https://cdn.fitapp.cn/vue/vip/7-7/0vip_avatar_07.png",
          text:
            "真的很不错 希望自己接下去能够坚持，坚持天天锻炼。每天很贴心的被提醒，感觉非常温馨。 总之，好评好评，希望一直这样。"
        },
        {
          uaerName: "qqq*kk",
          imgUrl: "https://cdn.fitapp.cn/vue/vip/7-7/0vip_avatar_08.png",
          text:
            "录制的课程里教练，声音，背景音乐，都比较喜欢，没有那么多花哨的东西。个人觉得比k**p好用！"
        }
      ],
      videoFlg: true,
      userIdex: 0
    };
  },
  computed: {
    /**
     * 定制训练饮食计划
     */
    optionLeft() {
      return {
        step: 0.4,
        direction: 2,
        limitMoveNum: 2,
        hoverStop: false,
        openTouch: false
      };
    },
    optionLeft2() {
      return {
        step: 0.5,
        direction: 2,
        limitMoveNum: 2,
        hoverStop: false,
        openTouch: false
      };
    }
  },
  created() {
    const _this = this;
    _this.getPageInfo();
    // this.abTest();

    /**
     * 右上角分享初始化
     */
    _this.$bridge.setShareInfo({
      title: "与国同庆，FitVIP会员买1送1年",
      link: this.$F.getOrigin() + "/subject/vipMall10-1",
      imgUrl: "https://cdn.fitapp.cn/vue/vip/10-1/share.png",
      desc: "全民健身大放送，会员买1送1年，好友同享，更有健康礼相送，快来！",
      ctitle: "与国同庆，FitVIP会员买1送1年",
      olapflg: "25",
      display: "1",
      shareList: "1,2"
    });
    /**
     * 设置微信分享
     */
    if (_this.browser == "wechat") {
      _this.setShare();
    }
    /**
     * 页面加载设置
     */
    _this.$bridge.setBridgeIsShowInfo({
      close: "1",
      pull: "0",
      prev: "1",
      noback: "0",
      slip: "1",
      service: "0"
    });
    /**接受客户端数据 */
    (window as any).sendWebMessage = function(val) {
      let obj = JSON.parse(val);
      if (obj.event == "click" && obj.name == "back") {
        if (!(cookie as any).get("vipBackFlg") && _this.actFlg == "0") {
          _this.animate("van-slide-down");
          (cookie as any).set("vipBackFlg", false, "1d");
        } else {
          _this.back();
        }
      }
    };
  },
  mounted() {
    let _this = this;
    setTimeout(function() {
      _this.showUser();
      /**
       * 下拉tab固定顶部
       */
      let topVal = (window as any).document.querySelector(".fixed-h").offsetTop;
      window.addEventListener(
        "scroll",
        () => {
          let scrollTop =
            window.pageYOffset ||
            document.documentElement.scrollTop ||
            document.body.scrollTop ||
            (window as any).document.querySelector(".bg").scrollTop;
          if (scrollTop > topVal) {
            _this.mallFixed = true;
            _this.goTopFlg = true;
          } else {
            _this.mallFixed = false;
            _this.goTopFlg = false;
          }
        },
        true
      );
    }, 2000);
  },
  methods: {
    /**
     * 获取基础数据
     */
    getPageInfo() {
      const _this = this;
      (_this as any).renewFlg = _this.$route.query.renew ? true : false; // 续费状态
      let trainid = _this.$route.query.trainid; //活动id
      _this.$axios
        .post(
          "/wuAPI/fitDcApi/fitH5Act2020/get2020VipActOctHomeResource",
          _this.$qs.stringify({
            "form.reqUid": _this.$F.userid() == 1 ? "10092" : _this.$F.userid(),
            "form.mbType": _this.system == "ios" ? "1" : "0",
            "form.channel": "dc2"
          })
        )
        .then(res => {
          window.console.log(res);
          if (res.data.result == "Y") {
            this.actFlg = res.data.entRet.actFlg;
            this.info = res.data.entRet;
            this.userList = res.data.entRet.lstMaj;
          } else {
            Toast("页面加载失败");
          }
        })
        .catch(res => {
          Toast("页面加载失败");
        });
    },
    /**
     * 打开客服
     */
    openCustomService() {
      this.$openPage.NativeOpenPage("3");
    },
    /**
     * 显示购买动画
     */
    showUser() {
      let ulDom: any = document.querySelector(".user-tips");
      ulDom = ulDom.querySelector("ul");
      let liDom: any = ulDom.querySelectorAll("li")[0];
      let cloneLiDome: any = ulDom.append(liDom.cloneNode(true));
      let liHeight = liDom.clientHeight;
      let num = 0;
      let userIdex = 0;
      setInterval(function() {
        userIdex++;
        const liDomArr = ulDom.querySelectorAll("li");
        for (let i = 0; i < liDomArr.length; i++) {
          liDomArr[i].className = "";
        }
        liDomArr[userIdex - 1].className = "add-fade";
        if (userIdex == 11) {
          userIdex = 0;
          ulDom.style.transition = "0s all";
          ulDom.style.transform = "translate(0, 0px)";
        } else {
          num = liHeight * userIdex;
          ulDom.style.transform = "translate(0, -" + num + "px)";
          ulDom.style.transition = "1.5s all";
        }
      }, 1500);
    },
    /**
     * 支付
     */
    payFn() {
      const _this = this;
      _this.leaveShow = false;
      if (_this.browser != "fit") {
        _this.goFit();
        return false;
      }
      if (_this.$B.system() != "ios") {
        const popGoodsInfo = {
          commodityId: _this.info.andComId, //商品id
          callType: "5",
          commodityTitle: _this.info.lstAct0GrpComData[0].grpTitle, //商品标题
          commodityComment: _this.info.comdDesc, //商品描述
          comment: _this.info.comdDesc, //商品描述
          price: _this.info.lstAct0GrpComData[0].activityPrice, //价格
          commodityType: "3",
          // buyComment: "一年打卡满300天全额返现",
          buyTitle: _this.info.lstAct0GrpComData[0].grpTitle //商品标题
        };
        let payObj = {
          showApplePay: "0",
          goodsInfo: JSON.stringify(popGoodsInfo),
          cusColor: "#DBB76A",
          androidStyleType: "0",
          callback: "successFn",
          paySort: "0,1",
          showPaySheet: "0"
        };

        if (_this.$F.dev() >= 635 && _this.$B.system() == "ios") {
          payObj.showPaySheet = "0";
          payObj.showApplePay = "1";
          payObj.paySort = "";
        }
        //支付
        _this.$bridge.NativeBridge("payPop", payObj);
        (window as any).successFn = function(msg) {
          msg = JSON.parse(msg);
          if (msg.type == "success") {
            _this.actFlg = "1";
            (window as any).gio("track", "web_payment_success", {
              target_module: "国庆活动"
            });
          }
        };
      } else {
        _this.$openPage.NativeOpenPage("5", _this.info.lstAct0GrpComData[0]);
      }
      (window as any).gio("track", "web_bnt_click", {
        target_id: _this.$F.userid(),
        target_module: "国庆活动",
        target_title: "立即开通"
      });
    },
    /**
     * 微信打开活动页面openFit
     */
    goFit() {
      this.$openPage.openFit("1", {
        url: this.$F.getOrigin() + "/subject/vipMall10-1"
      });
    },
    /**
     * 微信分享
     */
    setShare() {
      const shareInfo = {
        title: "与国同庆，FitVIP会员买1送1年",
        desc: "抓住这次机会，年前大蜕变，会员限时买1送1年啦！",
        link: this.$F.getOrigin() + "/subject/vipMall10-1",
        img: "https://cdn.fitapp.cn/vue/vip/10-1/share.png"
      };
      (this as any).wechatShare(shareInfo);
    },
    /**
     * 挽留弹出动画
     */
    animate(transitionName) {
      this.leaveShow = true;
      this.transitionName = transitionName;
    },
    /**
     * 返回页面
     */
    back() {
      this.$bridge.NativeBridge("blackAction", {});
    },
    /**
     * 打开课程详情
     */
    goTrain(id) {
      if (this.$B.browser() != "fit") {
        this.goFit();
        return false;
      }
      this.$openPage.NativeOpenPage("7", id);
    },
    /**
     * 打开课程库
     */
    goTrainList() {
      if (this.$B.browser() != "fit") {
        this.goFit();
        return false;
      }
      this.$openPage.NativeOpenPage("6", "8");
    },
    /**
     * 回到顶部
     */
    goTop() {
      window.scrollTo(0, 0);
    }
  }
});
