
































































































































































import Vue from "vue";
import scroll from "vue-seamless-scroll";
import { Toast, Lazyload, CountDown, Dialog } from "vant";
Vue.use(scroll)
  .use(Lazyload)
  .use(CountDown)
  .use(Dialog);
import wxShare from "../../tool/wechat";
export default Vue.extend({
  mixins: [wxShare],
  data() {
    return {
      time: 0, //倒计时时间
      fixedFlg: false, //是否漂浮
      textList: [], //中奖信息、
      buySucNum: "", //是否购买
      entCom: {
        //商品信息
        commodityId: "",
        commodityTitle: "",
        price: ""
      },
      imgList: [
        { url: "https://media.fitapp.cn/fit/h5Com/5year/plan1.png" },
        { url: "https://media.fitapp.cn/fit/h5Com/5year/plan2.png" },
        { url: "https://media.fitapp.cn/fit/h5Com/5year/plan3.png" },
        { url: "https://media.fitapp.cn/fit/h5Com/5year/plan4.png" }
      ],
      commontList: [
        { url: "https://media.fitapp.cn/fit/h5Com/5year/class1.png" },
        { url: "https://media.fitapp.cn/fit/h5Com/5year/class2.png" },
        { url: "https://media.fitapp.cn/fit/h5Com/5year/class3.png" },
        { url: "https://media.fitapp.cn/fit/h5Com/5year/class4.png" }
      ],
      commontList2: [
        { url: "https://media.fitapp.cn/fit/h5Com/5year/class5.png" },
        { url: "https://media.fitapp.cn/fit/h5Com/5year/class6.png" },
        { url: "https://media.fitapp.cn/fit/h5Com/5year/class7.png" },
        { url: "https://media.fitapp.cn/fit/h5Com/5year/class8.png" },
        { url: "https://media.fitapp.cn/fit/h5Com/5year/class9.png" }
      ]
    };
  },
  created() {
    this.getPageInfo();
    /**
     * 右上角分享初始化
     */
    this.$bridge.setShareInfo({
      title: "Fit5周年庆，买断1年好身材！",
      link: "https://www.fitapp.com.cn/vue/subject/5year",
      imgUrl: "https://media.fitapp.cn/fit/h5Com/5year/share.jpg",
      desc: "还送30天会员+天天果园+懒人听书等会员哦～",
      ctitle: "Fit5周年庆，买断1年好身材！",
      olapflg: "25",
      display: "1",
      shareList: "1,2"
    });
    /**
     * 页面加载设置
     */
    this.$bridge.setBridgeIsShowInfo({
      close: "1",
      pull: "1",
      prev: "1",
      noback: "0",
      slip: "1",
      service: "0"
    });
    /**
     * 设置微信分享
     */
    if (this.$B.browser() == "wechat") {
      this.setShare();
    }
  },
  mounted() {
    /**
     * 下拉tab固定顶部
     */
    let _this = this;
    let topVal = (window as any).document.querySelector(".p1").offsetTop;
    window.addEventListener(
      "scroll",
      () => {
        let scrollTop =
          document.documentElement.scrollTop ||
          document.body.scrollTop ||
          window.pageYOffset;
        if (scrollTop > topVal) {
          _this.fixedFlg = true;
        } else {
          _this.fixedFlg = false;
        }
      },
      true
    );
  },
  computed: {
    /**
     * 中奖信息滚动
     */
    optionSingleHeightTime() {
      return {
        singleHeight: 0.67,
        waitTime: 2500,
        openTouch: false,
        isSingleRemUnit: true
      };
    },
    /**
     * 图片滚动
     */
    optionLeft() {
      return {
        step: 0.4,
        direction: 2,
        limitMoveNum: 4,
        hoverStop: false,
        openTouch: false
      };
    },
    /**
     * 图片滚动
     */
    optionLeft2() {
      return {
        step: 0.6,
        direction: 2,
        limitMoveNum: 4,
        hoverStop: false,
        openTouch: false
      };
    }
  },
  methods: {
    //获取页面数据
    getPageInfo() {
      let _this = this;
      _this.$axios
        .post(
          "/wuAPI/fitDcApi/fitH5Act2020/fit5YearActReq",
          _this.$qs.stringify({
            "form.reqUid": "64789-vue",
            "form.userId": _this.$F.userid(),
            "form.channel": _this.$B.browser() == "fit" ? "dc2" : "wxShare",
            "form.mbType": _this.$B.system() == "ios" ? "1" : "0"
          })
        )
        .then(res => {
          window.console.log(res);
          _this.time = res.data.entRet.actETimeStamp * 1000;
          _this.textList = res.data.entRet.lstMjud;
          _this.entCom = res.data.entRet.entCom;
          _this.buySucNum = res.data.entRet.buySucNum;
          if (res.data.entRet.actEflg == 1) {
            _this.end();
          }
        })
        .catch(res => {
          Toast("页面加载失败");
        });
    },
    /**
     * 微信分享
     */
    setShare() {
      const shareInfo = {
        title: `Fit5周年庆，买断1年好身材！`,
        desc: `还送30天会员+天天果园+懒人听书等会员哦～`,
        link: "https://www.fitapp.com.cn/vue/subject/5year",
        img: "https://media.fitapp.cn/fit/h5Com/5year/share.jpg"
      };
      (this as any).wechatShare(shareInfo);
    },
    /**
     * 返回顶部
     */
    goTop() {
      document.documentElement.scrollTop = document.body.scrollTop = 0;
    },
    /**
     * 打开我的会员
     */
    goVip() {
      if (this.$B.browser() == "wechat") {
        this.goFit();
        return;
      }
      this.$openPage.NativeOpenPage("1");
    },
    /**
     * 支付
     */
    pay() {
      let _this = this;
      if (_this.$B.browser() == "wechat") {
        _this.goFit();
        return;
      }
      (window as any).gio("track", "web_bnt_click", {
        target_module: "抢购按钮",
        target_title: "五周年活动",
        target_id: _this.$F.userid()
      });
      var popGoodsInfo = {
        commodityId: _this.entCom.commodityId, //商品id
        callType: "3",
        commodityTitle: _this.entCom.commodityTitle, //商品标题
        commodityComment: _this.entCom.commodityTitle, //商品描述
        comment: _this.entCom.commodityTitle, //商品描述
        price: _this.entCom.price, //价格
        commodityType: "3",
        buyComment: "另赠1年+联合会员礼包",
        buyTitle: _this.entCom.commodityTitle //商品标题
      };
      if (this.buySucNum != "0") {
        popGoodsInfo.buyComment = "";
      }
      var payObj = {
        showPaySheet: "1",
        showApplePay: "0",
        goodsInfo: JSON.stringify(popGoodsInfo),
        cusColor: "#DBB76A",
        androidStyleType: "0",
        callback: "successFn",
        paySort: "0,1"
      };
      if (_this.$B.system() == "ios" && _this.$F.dev() >= "635") {
        payObj.showApplePay = "1";
        payObj.paySort = "";
      }
      //支付
      _this.$bridge.NativeBridge("payPop", payObj);
      //支付成功运行方法
      (window as any).successFn = function(msg: any) {
        msg = JSON.parse(msg);
        if (msg.type == "success") {
          this.buySucNum = "1";
        }
      };
    },
    /**
     * 跳转fit
     */
    goFit() {
      this.$openPage.openFit("1", {
        url: "https://www.fitapp.com.cn/vue/subject/5year"
      });
    },
    /**
     * 活动结束
     */
    end() {
      Dialog.alert({
        message: "活动已结束！"
      }).then(() => {
        // on close
      });
    }
  }
});
