






















































































import Vue from "vue";
import { Toast, Icon, Popup } from "vant";
Vue.use(Toast)
  .use(Icon)
  .use(Popup);
export default Vue.extend({
  data() {
    return {
      exchangeShowFlg: false, //兑换成功弹窗
      isChangeFlg: false, //确认是否兑换
      info: {
        energyval: "0",
        fcurval: "0"
      }
    };
  },
  created() {
    this.getPageInfo();
  },
  methods: {
    /**
     * 兑换F币
     */
    upchangeFn() {
      const _this = this;
      _this.isChangeFlg = false;
      _this.$axios
        .post(
          "/zhangAPI/SFitWeb/sfit/exchangeEnergy",
          _this.$qs.stringify({
            "form.userId": _this.$F.userid() == 1 ? "10092" : _this.$F.userid()
          })
        )
        .then(res => {
          if (res.data.result == "Y") {
            _this.exchangeShowFlg = true;
            _this.$bridge.NativeBridge("sendNativeMessage", {
              name: "energyExchange"
            });
          } else {
            Toast("页面加载失败");
          }
        })
        .catch(res => {
          Toast("页面加载失败");
        });
    },
    /**
     * 获取基础数据
     */
    getPageInfo() {
      const _this = this;
      _this.$axios
        .post(
          "/zhangAPI/SFitWeb/sfit/myEnergy",
          _this.$qs.stringify({
            "form.userId": _this.$F.userid() == 1 ? "10092" : _this.$F.userid()
          })
        )
        .then(res => {
          if (res.data.result == "Y") {
            let entData = res.data.entData;
            _this.info = entData;
          } else {
            Toast("页面加载失败");
          }
        })
        .catch(res => {
          Toast("页面加载失败");
        });
    },
    /**
    确定按钮
     */
    changeBtnFn() {
      this.exchangeShowFlg = false;
      this.info.energyval = "0";
      this.info.fcurval = "0";
    }
  }
});
