





















































import Vue from "vue";
import { Toast } from "vant";
import cookie from "vue-cookies";
Vue.use(Toast);
export default Vue.extend({
  data() {
    return {
      tel: "", //电话
      trainid: "4", //活动id
      isView: "0", //判断是否显示页面标题
      isDone: "0", //判断是否填写资料
      skipflg: "0", //是否显示跳过
      classtitle: "", //课程标题
      telBtnFlg: false, //填写电话按钮状态
      page: "0", //显示第几个页面
      levelFlg: "0" //级别选择
    };
  },
  created() {
    window.document.title =
      this.$route.query.page == "1" ? "选择你的等级" : "填写联系电话";
    this.trainid = this.$route.query.trainid + "";
    this.isView = this.$route.query.isView + "";
    this.isDone = this.$route.query.isDone + "";
    this.classtitle = this.$route.query.classtitle + "";
    if (this.$route.query.isDone == "1") {
      window.location.href =
        "/train/trainCode?trainid=" +
        this.$route.query.trainid +
        "&actuid=" +
        this.$route.query.actuid +
        "&classtitle=" +
        this.classtitle;
    } else if (this.$route.query.isDone == "1" && this.isView) {
      window.location.href =
        "/train/trainCode?trainid=" +
        this.$route.query.trainid +
        "&actuid=" +
        this.$route.query.actuid +
        "&classtitle=" +
        this.classtitle +
        "&isView=1";
    }
    this.skipflg = this.$route.query.page ? this.$route.query.page + "" : "0";
    this.page = this.$route.query.page ? this.$route.query.page + "" : "0";
    /**
     * 页面加载设置
     */
    this.$bridge.setBridgeIsShowInfo({
      close: "1",
      pull: "1",
      prev: "1",
      noback: "0",
      slip: "1",
      service: "0"
    });
  },
  methods: {
    /**
     * 下一步
     */
    nextFn() {
      if (!/^1[23456789]\d{9}$/.test(this.tel)) {
        Toast("请输入正确的手机号");
        return;
      }
      (cookie as any).set("tel", this.tel, 300);
      this.skipFn();
    },
    /**
     * 跳过
     */
    skipFn() {
      //   this.$router.push({
      //         "path": 'train/trainTel',
      //         "query": {
      //                 "trainid": this.$route.query.trainid.toString(),
      //                 "actuid": this.$route.query.actuid.toString(),
      //                 "classtitle": this.classtitle.toString(),
      //                 "page" : "1"
      //             }
      //     })
      window.location.href =
        "/train/trainTel?trainid=" +
        this.$route.query.trainid +
        "&actuid=" +
        this.$route.query.actuid +
        "&classtitle=" +
        this.classtitle +
        "&page=1";
    },
    /**
     * 提交电话等级
     */
    upTelLevel() {
      let _this = this;
      window.console.log((cookie as any).get("tel"));
      _this.$axios
        .post(
          "/wuAPI/fitDcApi/sptWxH5Xly/getTrainActUserOrderId4Resource",
          _this.$qs.stringify({
            "form.reqUid": _this.$F.userid(),
            "form.trainActId": _this.$route.query.trainid,
            "form.channel": "dc2",
            "form.reqFlg": "1",
            "form.actuId": _this.$route.query.actuid,
            "form.phoneNum": (cookie as any).get("tel"),
            "form.diff_grade": _this.levelFlg
          })
        )
        .then(res => {
          window.location.href =
            "/train/trainCode?trainid=" +
            this.$route.query.trainid +
            "&actuid=" +
            this.$route.query.actuid +
            "&classtitle=" +
            this.classtitle;
        })
        .catch(res => {
          Toast("提交失败，请联系客服");
        });
    }
  }
});
