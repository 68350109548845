












































































































































































import Vue from "vue";
import { Swipe, SwipeItem, Toast, Popup, Icon } from "vant";
const rule = [
  `1. 活动时间：6月12日10点-6月20日24点；<br />
    2. 活动期间，同一用户（同一用户名、设备、手机号都视为同一用户）仅限参与1次，重复购买将不附赠1年会员权益；<br />
    3. 由于Fit VIP会员服务是数字化商品，基于互联网服务的特殊性和国家相关管理规定，如因您的个人原因发生错误购买或无法正常享受会员权益，Fit不能退还相应会员费；<br />
    4. Fit保留本次活动的最终解释权。`,
  `1. 活动时间：6月12日10点-6月20日24点；<br />
    2. 活动期间，同一用户（同一用户名、设备、手机号都视为同一用户）仅限参与1次；<br />
    3. 活动仅限减脂营新用户和回购老用户（上个订单活动已结束超过1个月）参加；<br />
    4. 成功购买后，由于减脂营服务是数字化商品，基于互联网服务的特殊性和国家相关管理规定，如因您的个人原因发生错误购买或无法正常享受减脂营服务，Fit不能退还相应费用；<br />
    5. Fit保留本次活动在法律范围内的最终解释权。`,
  `1. 活动时间：6月12日10点-6月20日24点；<br />
    2. 活动期间，同一用户（同一用户名、设备、手机号都视为同一用户）仅限参与1次；<br />
    3. 活动仅限训练营新用户和回购老用户（上个订单活动已结束超过2个月）参加；<br />
    4. 成功购买后，由于训练营服务是数字化商品，基于互联网服务的特殊性和国家相关管理规定，如因您的个人原因发生错误购买或无法正常享受训练营服务，Fit不能退还相应费用；<br />
    5. Fit保留本次活动在法律范围内的最终解释权。
    `
];
Vue.use(Swipe)
  .use(SwipeItem)
  .use(Toast)
  .use(Icon)
  .use(Popup);
export default Vue.extend({
  data() {
    return {
      sex: "0",
      info: {
        entMeda: "",
        lstCom: [
          {
            actPrice: "",
            actTitle: "",
            comFlg: "",
            commodityDesc: "",
            commodityId: "",
            commodityPrice: "",
            commodityTitle: "",
            endDate: "",
            imgUrl: "",
            periodNums: "",
            tarVal: "",
            type: ""
          },
          {
            actPrice: "",
            actTitle: "",
            comFlg: "",
            commodityDesc: "",
            commodityId: "",
            commodityPrice: "",
            commodityTitle: "",
            endDate: "",
            imgUrl: "",
            periodNums: "",
            tarVal: "",
            type: ""
          },
          {
            actPrice: "",
            actTitle: "",
            comFlg: "",
            commodityDesc: "",
            commodityId: "",
            commodityPrice: "",
            commodityTitle: "",
            endDate: "",
            imgUrl: "",
            periodNums: "",
            tarVal: "",
            type: "",
            redCode: ""
          }
        ]
      },
      ruleShow: false,
      infoText: ""
    };
  },
  created() {
    this.sex = this.$F.sex();
    this.getPageInfo();
  },
  methods: {
    /**
     * 获取基础数据
     */
    getPageInfo(reqFlg = 1) {
      const _this = this;
      _this.$axios
        .post(
          "/wuAPI/fitDcApi/fitH5Act/getGrpActComMcResource",
          _this.$qs.stringify({
            "form.reqUid": "64789-vue",
            "form.userId": _this.$F.userid() == 1 ? "10092" : _this.$F.userid(),
            "form.channel": "dc2",
            "form.mbType": _this.$B.system() == "ios" ? "1" : "0",
            "form.grpType": "210618ACT",
            "form.version": _this.$F.dev(1)
          })
        )
        .then(res => {
          if (res.data.result == "Y") {
            let entRet = res.data.entRet;
            _this.info = entRet;
          } else {
            Toast("页面加载失败");
          }
        })
        .catch(res => {
          Toast("页面加载失败");
        });
    },
    /**
     * 活动规则
     */
    rule(index) {
      this.ruleShow = true;
      this.infoText = rule[index];
    },
    /**
     * 支付
     */
    payFn(index) {
      const _this = this;
      if (_this.$F.dev() >= 620) {
        const popGoodsInfo = {
          commodityId: _this.info.lstCom[index].commodityId, //商品id
          callType: "2",
          commodityTitle: _this.info.lstCom[index].commodityTitle, //商品标题
          commodityComment: _this.info.lstCom[index].commodityDesc, //商品描述
          comment: _this.info.lstCom[index].commodityDesc, //商品描述
          price: _this.info.lstCom[index].commodityPrice, //价格
          commodityType: "3",
          buyComment: _this.info.lstCom[index].commodityDesc,
          buyTitle: _this.info.lstCom[index].commodityTitle //商品标题
        };
        let payObj = {
          showPaySheet: "1",
          showApplePay: "0",
          goodsInfo: JSON.stringify(popGoodsInfo),
          cusColor: "#DBB76A",
          callback: "successFn",
          androidStyleType: "15",
          paySort: "1,0"
        };
        if (_this.$F.dev() >= 635 && _this.$B.system() == "ios") {
          payObj.showPaySheet = "0";
          payObj.showApplePay = "1";
          payObj.paySort = "";
        }
        //支付
        _this.$bridge.NativeBridge("payPop", payObj);
        (window as any).successFn = function(msg) {
          msg = JSON.parse(msg);
          if (msg.type == "success") {
            if (index == 2) {
              _this.info.lstCom[index].comFlg = "3";
            } else {
              _this.info.lstCom[index].comFlg = "1";
            }
            _this.$bridge.NativeBridge("sendNativeMessage", {
              name: "energyExchange"
            });
            _this.getPageInfo();
          }
        };
      }
    },
    /**
     * 复制打开微信
     */
    copyFn(index) {
      if (this.info.lstCom[index].comFlg === "4") {
        Toast("兑换码已过期");
        return false;
      }
      var obj = {
        text: this.info.lstCom[index].redCode,
        needOpenWexin: "0"
      };
      this.$bridge.NativeBridge("copyAction", obj, 0);
      this.$openPage.NativeOpenPage("10");
    },
    /**
     * 打开课程详情
     */
    goTrain(id) {
      this.$openPage.NativeOpenPage("13", id);
    },
    /**
     * 跳转训练营
     */
    goTab() {
      this.$openPage.NativeOpenPage("17");
    },
    /**
     * 已购买
     */
    tips(index) {
      if (this.info.lstCom[index].comFlg === "6") {
        Toast("该活动仅限新用户和回购老用户参加");
      } else {
        Toast("您已参加该活动");
      }
    },
    goPlan(index) {
      window.location.href = this.info.lstCom[index].tarVal;
    }
  }
});
