

















import Vue from "vue";
export default Vue.extend({
  beforeCreate() {
    document.title = "活动规则";
  }
});
