



































































































import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      type: "0",
      code: ""
    };
  },
  created() {
    (this as any).type = this.$route.query.type;
    (this as any).code = this.$route.query.code;
  },
  methods: {
    /**
     * 复制打开微信
     */
    copyFn(val) {
      var obj = {
        text: val,
        needOpenWexin: "0"
      };
      this.$bridge.NativeBridge("copyAction", obj, 0);
    }
  }
});
