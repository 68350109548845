






































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import { slider, slideritem } from "vue-concise-slider";
import { Toast, Swipe, SwipeItem, Lazyload, Icon, Popup } from "vant";
Vue.use(Toast)
  .use(Swipe)
  .use(SwipeItem)
  .use(Lazyload)
  .use(Icon)
  .use(Popup);
export default Vue.extend({
  data() {
    return {
      info: {
        //基础信息
        actHeadImg: "",
        entActIntrJzy15: {},
        lstTargetClasType: []
      },
      options: {
        itemAnimation: true,
        centeredSlides: true,
        loopedSlides: 2,
        slidesToScroll: 1,
        loop: true,
        autoplay: "3000"
      },
      tabFixed: false, //tab是否固定顶部
      maxAfCouponCnt: "", //是否是会员
      coachFlg: 1, //教练index
      tabShowFlg: 1, //tab切换
      cmtFlg: 1, //评论index
      renewFlg: false, //续费
      renewIndex: 0, //续费index
      getTelFlg: false, //瘦身计划
      tel: "", //瘦身计划电话
      mallFlg: false, //购买弹出
      lstTargetClasType: {
        //商品信息
        priceFlg: 0,
        classType: "",
        periodNums: ""
      },
      mallTabFlg: 0 //商品tab
    };
  },
  created() {
    this.getPageInfo();
    /**
     * 页面加载设置
     */
    this.$bridge.setBridgeIsShowInfo({
      close: "1",
      pull: "1",
      prev: "1",
      noback: "0",
      slip: "1",
      service: "0"
    });
    /**
     * 右上角分享初始化
     */
    this.$bridge.setShareInfo(null);
  },
  mounted() {
    /**
     * 下拉tab固定顶部
     */
    let _this = this;
    let topVal = (window as any).document.querySelector(".fat-index-fixed")
      .offsetTop;
    window.addEventListener(
      "scroll",
      () => {
        let scrollTop =
          document.documentElement.scrollTop ||
          document.body.scrollTop ||
          window.pageYOffset;
        if (scrollTop > topVal) {
          _this.tabFixed = true;
        } else {
          _this.tabFixed = false;
        }
      },
      true
    );
  },
  methods: {
    //获取页面数据
    getPageInfo() {
      let _this = this;
      (_this as any).renewFlg = _this.$route.query.renew ? true : false; // 续费状态
      let trainid = _this.$route.query.trainid; //活动id
      _this.$axios
        .post(
          "/wuAPI/fitDcApi/sptWxH5Jzy/getTrainActResource",
          _this.$qs.stringify({
            "form.reqUid": _this.$F.userid(),
            "form.trainActId": trainid,
            "form.channel": "dc2",
            "form.phoneType": _this.$B.system() == "ios" ? "1" : "0",
            "form.version": _this.$F.dev(1)
          })
        )
        .then(res => {
          window.console.log(res);
          _this.info = res.data.entRet;
          _this.lstTargetClasType = res.data.entRet.lstTargetClasType[0];
        })
        .catch(res => {
          Toast("页面加载失败");
        });
    },
    /**
     * 教练swipe
     */
    onCoachFn(index: any) {
      this.coachFlg = index + 1;
    },
    /**
     *
     */
    onCmtFn(index: any) {
      this.cmtFlg = index + 1;
    },
    /**
     * tab切换
     */
    tabFn(index: any) {
      this.tabShowFlg = index;
    },
    /**
     * 我要加入
     */
    showMall() {
      if (this.$F.getTourist() == 1) {
        this.$bridge.NativeBridge("touristJumpToLogin");
        return false;
      }
      this.mallFlg = true;
    },
    /**
     * 提交瘦身计划
     */
    upTel() {
      let tel = this.tel;
      let _this = this;
      if (!/^1[23456789]\d{9}$/.test(tel)) {
        Toast("请输入正确的手机号");
        return;
      }
      _this.$axios
        .post(
          "/zhangAPI/SFitWeb/sfitphytest/addCampProInfo",
          _this.$qs.stringify({
            "form.userId": _this.$F.userid(),
            "form.version": "4.0",
            "form.phoneNumber": tel
          })
        )
        .then(res => {
          if (res.data.result == "Y") {
            Toast("提交成功！稍后会有你的专属课程顾问和你联系，请耐心等待");
            _this.tel = "";
            _this.getTelFlg = false;
          } else {
            Toast("提交失败，请联系客服！");
          }
        })
        .catch(res => {
          Toast("提交失败，请联系客服！");
        });
    },
    /**
     * 打开在线客服
     */
    openCustomService() {
      this.$openPage.NativeOpenPage("3", "xiaoshou");
    },
    /**
     * 切换商品
     */
    changeMall(index: any) {
      if (this.mallTabFlg == index) {
        return false;
      }
      this.mallTabFlg = index;
      this.lstTargetClasType = this.info.lstTargetClasType[index];
    },
    /**
     * 打开我的会员
     */
    goVip() {
      this.$openPage.NativeOpenPage("1");
    },
    /**
     * 选择续费课程
     */
    renewFn(index: any) {
      this.renewIndex = index;
      this.lstTargetClasType = this.info.lstTargetClasType[index];
    },
    /**
     * 确认订单
     */
    confirmFn() {
      this.$router.push({
        path: "/fat/creatOrder",
        query: {
          trainid: this.$route.query.trainid,
          class: this.lstTargetClasType.classType,
          periodNums: this.lstTargetClasType.periodNums,
          target:""+this.mallTabFlg
        }
      });
    },
    /**
     * 获取优惠券
     */
    getCoupon() {
      if (this.$F.getTourist() == 1) {
        this.$bridge.NativeBridge("touristJumpToLogin");
        return false;
      }
      let _this = this;
      let trainid = _this.$route.query.trainid; //活动id
      _this.$axios
        .post(
          "/wuAPI/fitDcApi/sptWxH5Jzy/getTrainActCouponResource",
          _this.$qs.stringify({
            "form.reqUid": _this.$F.userid(),
            "form.trainActId": trainid,
            "form.channel": "dc2"
          })
        )
        .then(res => {
          if (res.data.result == "Y") {
            Toast(res.data.entSucc.succShowMessage);
            setTimeout(function() {
              _this.mallFlg = true;
            }, 1000);
          } else {
            Toast(res.data.entError.errorShowMessage);
          }
        })
        .catch(res => {
          Toast("获取优惠券失败，请联系客服");
        });
    }
  },
  components: {
    slider,
    slideritem
  }
});
