















































































import Vue from "vue";
import { Toast, Popup } from "vant";
import wxShare from "../../tool/wechat";
Vue.use(Toast).use(Popup);
let isClick = true;
export default Vue.extend({
  mixins: [wxShare],
  data() {
    return {
      info: [
        {
          userFlg: 0,
          endDate: "",
          commodityId: "",
          commodityTitle: "",
          commodityDesc: "",
          commodityPrice: ""
        },
        {
          userFlg: 0,
          targetId: "",
          endDate: "",
          commodityId: "",
          commodityTitle: "",
          commodityDesc: "",
          commodityPrice: ""
        },
        {
          userFlg: 0,
          endDate: "",
          telNumber: ""
        },
        {
          userFlg: 0
        }
      ],
      moreFlg: "查看更多",
      show: false
    };
  },
  created() {
    let _this = this;
    _this.getPageInfo();
    _this.inputReset();
    /**
     * 右上角分享初始化
     */
    _this.$bridge.setShareInfo({
      title: "“牛”转好运🧧领开门红好礼",
      link: this.$F.getOrigin() + "/subject/2021-2-7",
      imgUrl: "https://cdn.fitapp.cn/vue/subject/2021-2-7/share.jpg",
      desc: "查收你的运动回忆，生成专属新春好运",
      ctitle: "“牛”转好运🧧领开门红好礼",
      olapflg: "25",
      display: "1",
      shareList: "1,2"
    });
    /**
     * 页面加载设置
     */
    _this.$bridge.setBridgeIsShowInfo({
      close: "1",
      pull: "0",
      prev: "1",
      noback: "0",
      slip: "1",
      service: "0"
    });
    /**
     * 设置微信分享
     */
    if (this.$B.browser() == "wechat") {
      this.setShare();
    }
  },
  methods: {
    /**
     * 获取基础数据
     */
    getPageInfo() {
      const _this = this;
      _this.$axios
        .post(
          "/wuAPI/fitDcApi/fitH5Act/getUserAnnualPrize",
          _this.$qs.stringify({
            "form.reqUid": "64789-vue",
            "form.userId": _this.$F.userid() == 1 ? "10092" : _this.$F.userid(),
            "form.channel": "dc2"
          })
        )
        .then(res => {
          if (res.data.result == "Y") {
            let entRet = res.data.entRet;
            _this.info = entRet;
            setTimeout(function() {
              _this.$bridge.NativeBridge("sendNativeMessage", {
                name: "energyExchange"
              });
            }, 2000);
          } else {
            Toast("页面加载失败");
          }
        })
        .catch(res => {
          Toast("页面加载失败");
        });
    },
    /**
     * 微信分享
     */
    setShare() {
      const shareInfo = {
        title: `“牛”转好运🧧领开门红好礼`,
        desc: `查收你的运动回忆，生成专属新春好运`,
        link: this.$F.getOrigin() + "/subject/2021-2-7",
        img: "https://cdn.fitapp.cn/vue/subject/2021-2-7/share.jpg"
      };
      (this as any).wechatShare(shareInfo);
    },
    /**
     * 查看更多
     */
    moreFn() {
      this.moreFlg = this.moreFlg == "查看更多" ? "收起" : "查看更多";
    },
    /**
     * 复制打开微信
     */
    copyFn() {
      var obj = {
        text: this.info[1].targetId,
        needOpenWexin: "0"
      };
      this.$bridge.NativeBridge("copyAction", obj, 0);
      this.$openPage.NativeOpenPage("10");
    },
    /**
     * 跳转会员中心
     */
    goVip() {
      this.$openPage.NativeOpenPage("1");
    },
    /**
     * 提交瘦身计划
     */
    upTel() {
      if (isClick) {
        isClick = false;
        let tel: any = this.info[2].telNumber;
        let _this = this;
        if (!/^1[23456789]\d{9}$/.test(tel)) {
          Toast("请输入正确的手机号");
          isClick = true;
          return;
        }
        _this.$axios
          .post(
            "/zhangAPI/SFitWeb/sfitphytest/addCampProInfo",
            _this.$qs.stringify({
              "form.userId": _this.$F.userid(),
              "form.version": "4.0",
              "form.phoneNumber": tel,
              "form.flg": "1",
              "form.flag": "5"
            })
          )
          .then(res => {
            if (res.data.result == "Y") {
              Toast("提交成功！稍后会有你的专属课程顾问和你联系，请耐心等待");
              _this.info[2].telNumber = "";
              _this.info[2].userFlg = 1;
              _this.show = false;
            } else {
              Toast("提交失败，请联系客服！");
            }
            isClick = true;
          })
          .catch(res => {
            Toast("提交失败，请联系客服！");
            isClick = true;
          });
      }
    },
    /**
     * 支付
     */
    payFn(index) {
      const _this = this;
      if (_this.$F.dev() >= 620) {
        const popGoodsInfo = {
          commodityId: _this.info[index].commodityId, //商品id
          callType: "2",
          commodityTitle: _this.info[index].commodityTitle, //商品标题
          commodityComment: _this.info[index].commodityDesc, //商品描述
          comment: _this.info[index].commodityDesc, //商品描述
          price: _this.info[index].commodityPrice, //价格
          commodityType: "3",
          buyComment: _this.info[index].commodityDesc,
          buyTitle: _this.info[index].commodityTitle //商品标题
        };
        let payObj = {
          showPaySheet: "1",
          showApplePay: "0",
          goodsInfo: JSON.stringify(popGoodsInfo),
          cusColor: "#DBB76A",
          callback: "successFn",
          androidStyleType: "15",
          paySort: "1,0"
        };
        //支付
        _this.$bridge.NativeBridge("payPop", payObj);
        (window as any).successFn = function(msg) {
          msg = JSON.parse(msg);
          if (msg.type == "success") {
            _this.info[index].userFlg = 1;
            (window as any).gio("track", "web_bnt_click", {
              target_module: index == 0 ? "会员支付成功" : "细腰马甲支付成功",
              target_title: "Fit暖心大礼包",
              target_id: _this.$F.userid()
            });
            _this.$bridge.NativeBridge("sendNativeMessage", {
              name: "energyExchange"
            });
            _this.getPageInfo();
          }
        };
      }
      (window as any).gio("track", "web_bnt_click", {
        target_module: index == 0 ? "购买会员" : "购买会员细腰马甲",
        target_title: "Fit暖心大礼包",
        target_id: _this.$F.userid()
      });
    },
    /**
     * 输入框适配
     */
    inputReset() {
      if (this.$F.system != "ios") {
        window.console.log(111);
        let d: any = document;
        window.addEventListener("resize", function() {
          window.console.log(222);
          window.setTimeout(function() {
            d.activeElement.scrollIntoViewIfNeeded();
          }, 0);
        });
      }
    }
  }
});
