




















































import Vue from "vue";
import { Toast } from "vant";
Vue.use(Toast);
let timer: any = null;
export default Vue.extend({
  data() {
    return {
      d: "00",
      h: "00",
      m: "00",
      s: "00",
      trainid: "4", //活动id
      isView: "",
      endTime: 0, //倒计时
      classTitle: "",
      info: {
        //基础数据
        qunCd: ""
      }
    };
  },
  created() {
    this.trainid = this.$route.query.trainid + "";
    this.isView = this.$route.query.isView + "";
    this.classTitle = this.$route.query.classtitle + "";
    this.getPageInfo();
    /**
     * 页面加载设置
     */
    this.$bridge.setBridgeIsShowInfo({
      close: "1",
      pull: "1",
      prev: "1",
      noback: "0",
      slip: "1",
      service: "0"
    });
  },
  mounted() {
    this.leftTimer();
  },
  methods: {
    //获取页面数据
    getPageInfo() {
      let _this = this;
      _this.$axios
        .post(
          "/wuAPI/fitDcApi/sptWxH5Xly/getTrainActUserOrderId4Resource",
          _this.$qs.stringify({
            "form.reqUid": _this.$F.userid(),
            "form.trainActId": _this.$route.query.trainid,
            "form.channel": "dc2",
            "form.reqFlg": 3,
            "form.actuId": _this.$route.query.actuid
          })
        )
        .then(res => {
          window.console.log(res);
          _this.info = res.data.entRet;
          _this.endTime = parseInt(res.data.entRet.leftStartSeconds);
        })
        .catch(res => {
          Toast("页面加载失败");
        });
    },
    /**
     * 倒计时
     */
    leftTimer() {
      let _this = this;
      let obj = (window as any).document.querySelector(".train-code-time");
      timer = setInterval(function() {
        let dhms = _this.$F.countDown(_this.endTime);
        _this.endTime--;
        _this.d = dhms.d;
        _this.h = dhms.h;
        _this.m = dhms.m;
        _this.s = dhms.s;
        if (
          dhms.d == "00" &&
          dhms.h == "00" &&
          dhms.m == "00" &&
          dhms.s == "00"
        ) {
          clearInterval(timer);
          timer = null;
        }
      }, 1000);
    },
    /**
     * 复制打开微信
     */
    copyFn() {
      var obj = {
        text: "fittrainingcamp2",
        needOpenWexin: "1"
      };
      this.$bridge.NativeBridge("copyAction", obj, 0);
    }
  }
});
