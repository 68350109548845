











import Vue from "vue";
import * as F from "@/tool/fitTool";
export default Vue.extend({
  mounted() {
    /**
     * GIO代码统计
     */
    const url = window.location.href;
    (window as any).gio("init", "bef0128ad1b3ea56", {});
    (window as any).gio("send");
    (window as any).gio("track", "web_page_view", {
      target_comment: url,
      target_id: F.userid()
    });
    (window as any).gio("page.set", "page_name", window.document.title);
    if (F.userid() != 1) {
      (window as any).gio("setUserId", F.userid());
    }
    window.console.log("页面地址：" + url);
  }
});
