















import Vue from "vue";
import { Toast, Lazyload, Popup } from "vant";
let timer: any;
Vue.use(Toast)
  .use(Lazyload)
  .use(Popup);
export default Vue.extend({
  data() {
    return {
      code: ""
    };
  },
  created() {
    this.code = this.$route.query.code.toString();
  },
  methods: {
    /**
     * 复制打开微信
     */
    copyFn() {
      var obj = {
        text: this.code,
        needOpenWexin: "0"
      };
      this.$bridge.NativeBridge("copyAction", obj, 0);
      this.$openPage.NativeOpenPage("10");
    }
  }
});
