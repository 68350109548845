









import Vue from "vue";
export default Vue.extend({
  beforeCreate() {
    if (this.$B.system() == "ios") {
      window.location.href = "https://apps.apple.com/us/app/id1462737983";
    } else {
      window.location.href = "http://fir.dev.fitapp.cn/fitvip";
      //   window.location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.sportq.fit.vipteach";
    }
  }
});
