


















import Vue from "vue";
import { Lazyload } from "vant";
Vue.use(Lazyload);
export default Vue.extend({
  data() {
    return {
      imgList: [
        "https://cdn.fitapp.cn/vue/subject/2021-1-21/fit01.jpg",
        "https://cdn.fitapp.cn/vue/subject/2021-1-21/fit02.jpg",
        "https://cdn.fitapp.cn/vue/subject/2021-1-21/fit03.jpg",
        "https://cdn.fitapp.cn/vue/subject/2021-1-21/fit04.jpg",
        "https://cdn.fitapp.cn/vue/subject/2021-1-21/fit05.jpg",
        "https://cdn.fitapp.cn/vue/subject/2021-1-21/fit06.jpg",
        "https://cdn.fitapp.cn/vue/subject/2021-1-21/fit07.jpg",
        "https://cdn.fitapp.cn/vue/subject/2021-1-21/fit08.jpg"
      ]
    };
  }
});
