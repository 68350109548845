













































































import Vue from "vue";
import scroll from "vue-seamless-scroll";
import wxShare from "../../tool/wechat";
import cookie from "vue-cookies";
import { Toast, Lazyload, Popup, CountDown, Dialog } from "vant";
Vue.use(Toast)
  .use(Lazyload)
  .use(CountDown)
  .use(Dialog)
  .use(Popup);
let isClick = true;
let isbackTipsClick = true; //是否点击过返回
export default Vue.extend({
  data() {
    return {
      loadFlg: false,
      backTips: false, //挽留弹出
      // userImg: "", //购买会员用户头像
      // userName: "", //购买会员用户名称
      userList: [],
      info: {
        joinFlg: "",
        actIntrJsonJzy15Entity: {
          lstHead: []
        },
        remainNum: 0
      },
      time: 0 //倒计时
    };
  },
  created() {
    const _this = this;
    _this.getPageInfo();
    /**接受客户端数据 */
    (window as any).sendWebMessage = function(val) {
      let obj = JSON.parse(val);
      if (obj.event == "click" && obj.name == "back") {
        if (isbackTipsClick) {
          isbackTipsClick = false;
          let newUserbackFlg = (cookie as any).get("newUserbackFlg")
            ? (cookie as any).get("newUserbackFlg")
            : 0;
          if (newUserbackFlg != 3) {
            _this.backTips = true;
            (cookie as any).set(
              "newUserbackFlg",
              parseInt(newUserbackFlg) + 1,
              "1d"
            );
          } else {
            var openObj = {};
            _this.back();
          }
        } else {
          _this.back();
        }
      }
    };
  },
  mounted() {
    let _this = this;
    setTimeout(function() {
      _this.showUser();
    }, 2000);
  },
  methods: {
    /**
     * 获取基础数据
     */
    getPageInfo() {
      const _this = this;
      _this.$axios
        .post(
          "/wuAPI/fitDcApi/fitH5Act/getXsyH5DataResource",
          _this.$qs.stringify({
            "form.reqUid": "64789-vue",
            "form.userId": _this.$F.userid() == 1 ? "10092" : _this.$F.userid(),
            "form.channel": "dc2"
          })
        )
        .then(res => {
          if (res.data.result == "Y") {
            _this.info = res.data.entRet;
            _this.time = res.data.entRet.countDown * 1000;
            _this.userList = res.data.entRet.lstMaj;
          } else {
            Toast("页面加载失败");
          }
        })
        .catch(res => {
          Toast("页面加载失败");
        });
    },
    /**
     * 报名
     */
    join() {
      const _this = this;
      if (isClick) {
        if (_this.$F.dev() < 648) {
          Dialog.confirm({
            title: "温馨提示",
            message: "当前版本过低，请升级至最新版本后参加活动。",
            confirmButtonText: "去升级",
            cancelButtonText: "取消"
          }).then(() => {
            if (_this.$B.system() == "ios") {
              _this.$bridge.NativeBridge("clickDw", "跳转下载");
            } else {
              _this.$openPage.NativeOpenPage("101");
            }
          });
          return false;
        }

        _this.loadFlg = true;
        isClick = false;
        _this.$axios
          .post(
            "/wuAPI/fitDcApi/fitH5Act/joinXsyH5Req",
            _this.$qs.stringify({
              "form.reqUid": "64789-vue",
              "form.userId":
                _this.$F.userid() == 1 ? "10092" : _this.$F.userid(),
              "form.channel": "dc2",
              "form.userAgent": window.navigator.userAgent
            })
          )
          .then(res => {
            _this.loadFlg = false;
            if (res.data.result == "Y") {
              _this.$bridge.NativeBridge("sendNativeMessage", {
                name: "newUserJoinSuccess"
              });
              _this.info.joinFlg = "1";
              window.location.href =
                "/newuserSuccess?randomCd=" +
                res.data.entRet.randomCd +
                "&advWxcd=" +
                res.data.entRet.advWxcd;
            } else {
              Toast("报名失败，请联系客服");
            }
            isClick = true;
          })
          .catch(res => {
            Toast("页面加载失败");
            isClick = true;
          });
      }
    },
    /**
     * 显示购买动画
     */
    showUser() {
      let box: any = document.querySelector(".user-tips");
      // let ulDom: any = document.querySelector(".user-tips");
      let ulDom = box.querySelector("ul");
      let liDom: any = ulDom.querySelectorAll("li")[0];
      let cloneLiDome: any = ulDom.append(liDom.cloneNode(true));
      let liHeight = liDom.clientHeight;
      let num = 0;
      let userIdex = 0;
      let timer = setInterval(function() {
        userIdex++;
        const liDomArr = ulDom.querySelectorAll("li");
        for (let i = 0; i < liDomArr.length; i++) {
          liDomArr[i].className = "";
        }
        liDomArr[userIdex - 1].className = "add-fade";
        if (userIdex == 11) {
          userIdex = 0;
          ulDom.style.transition = "0s all";
          ulDom.style.transform = "translate(0, 0px)";
        } else {
          num = liHeight * userIdex;
          ulDom.style.transform = "translate(0, -" + num + "px)";
          ulDom.style.transition = "1.5s all";
        }
        if (userIdex == 10) {
          box.style.transition = "1.5s all";
          box.style.opacity = "0";
          clearInterval(timer);
        }
      }, 3000);
    },
    /**
     * 返回页面
     */
    back() {
      this.$bridge.NativeBridge("blackAction", {});
    }
  }
});
