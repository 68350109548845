





























































import Vue from "vue";
import { Search, Toast } from "vant";
Vue.use(Search).use(Toast);
export default Vue.extend({
  data() {
    return {
      searchFlg: false, //是否搜索
      cusPlanNum: 0, //搜索到的计划条数
      value: "",
      planList: [] //搜搜计划数据
    };
  },
  methods: {
    /**
     * 搜索
     */
    onSearch(val) {
      let _this = this;
      _this.$axios
        .post(
          "/wuAPI/vipDcApi/cusPlanH5/getCusPlan",
          _this.$qs.stringify({
            "form.reqUid": "64789-vue",
            "form.userId": _this.$F.userid(),
            "form.channel": "dc2",
            "form.reqParam": val
          })
        )
        .then(res => {
          window.console.log(res);
          const json = res.data;
          if (json.result === "Y") {
            if (json.entRet.cusPlanNum == 0) {
              _this.searchFlg = true;
              _this.planList = [];
              return false;
            }
            _this.searchFlg = true;
            _this.cusPlanNum = json.entRet.cusPlanNum;
            _this.planList = json.entRet.lstCusPlan;
          }
        })
        .catch(res => {
          Toast("页面加载失败");
        });
    },
    /**
     * 编辑和查看日程
     */
    editUserPlan(userid, headImg, name, planid, cusStatus) {
      window.console.log(31231);
      window.location.href =
        "/cjsj/userPlan?userid=" +
        userid +
        "&headImg=" +
        headImg +
        "&name=" +
        name +
        "&planid=" +
        planid +
        "&cusStatus=" +
        cusStatus;
    }
  }
});
